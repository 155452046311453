import React, { useState, useRef } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  CloseIcon,
  FacebookIcon,
  GoogleIcon,
} from '../../styles'
import {
  ContentWrapper,
  Media,
  Content,
  Header,
  Title,
  SubTitle,
  Field,
  Label,
  StyledInput,
  ForgotPasswordLink,
  SubmitButton,
  AlternativeButton,
  OrText,
  OrTextBorder,
  SwitchModeLink,
  ErrorMessage,
} from './styles'

import { useAppContext } from '../../../context/state';
import { getUserData, signInUser } from '../../../firebase/user';
import {
  getIsFetching,
  setIsFetching,
  setShowSignUp,
  setShowForgot,
  close,
} from '../../../store/user'
import { getIsAdmin } from '../../helpers'


const LoginModal = () => {
  const dispatch = useDispatch()
  const onCloseClick = () => dispatch(close())
  const onSignUpClick = () => dispatch(setShowSignUp())
  const onForgotClick = e => {
    e.preventDefault()
    dispatch(setShowForgot())
  }

  const isFetching = useSelector(getIsFetching)
  const { setUserData } = useAppContext()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const history = useHistory()

  const submitBtn = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()
    if (email.trim() === '' || password.trim() === '') {
      setError('Please fill out all fields')
    } else {
      dispatch(setIsFetching(true))
      signInUser(
        { email, password },
        () => {
          getUserData(setUserData, (newData) => {
            if (!newData) return
            if (getIsAdmin(newData)) {
              history.push({
                pathname: '/admin/home'
              })
            }
          })
          dispatch(setIsFetching(false))
          onCloseClick()
        },
        (error) => {
          dispatch(setIsFetching(false))
          setError(error)
        }
      )
    }
  };

  const responseFacebook = (response) => {
    if (response && response.name) {
      setEmail(response.email.toString().trim())
      setPassword('placeholder123')
      setTimeout(() => {
        submitBtn.current.click()
      }, 200)
    }
  };

  const responseGoogle = async (googleData) => {
    if (googleData.profileObj) {
      const data = googleData.profileObj
      setEmail(data.email)
      setPassword('placeholder123')
      setTimeout(() => {
        submitBtn.current.click()
      }, 200)
    }
  };

  return (
    <ContentWrapper>
      <Media />
      <Content>
        <Header>
          <div>
            <Title>Sign In</Title>
            <SubTitle>
              Welcome back to Yedding! Select method to sign in:
            </SubTitle>
          </div>
          <button onClick={onCloseClick}>
            <CloseIcon />
          </button>
        </Header>
        <form onSubmit={handleSubmit}>
          <Field>
            <Label>Email</Label>
            <StyledInput
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="example@gmail.com"
            />
          </Field>
          <Field>
            <Label>Password</Label>
            <StyledInput
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="**************"
            />
          </Field>
          <ForgotPasswordLink>
            <input
              type="button"
              onClick={onForgotClick}
              value="Forgot Password"
            />
          </ForgotPasswordLink>
          {!!error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <SubmitButton
            type="submit"
            disabled={isFetching}
            value={isFetching ? 'Loading...' : 'Sign In'}
          />
        </form>
        <OrText>
          <OrTextBorder style={{ left: 0 }} />
          <OrTextBorder style={{ right: 0 }} />
          OR
        </OrText>
        <GoogleLogin
          style={{ width: '100%', display: 'flex' }}
          clientId="733198051031-bma609juagpsjeoagjt5c6r03rkhfej0.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          render={props => (
            <AlternativeButton {...props}>
              <GoogleIcon />
              Sign In with Google
            </AlternativeButton>
          )}
        />
        <FacebookLogin
          appId="273134088032579"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          render={props => (
            <AlternativeButton {...props}>
              <FacebookIcon />
              Sign In with Facebook
            </AlternativeButton>
          )}
        />
        <SwitchModeLink>
          Don't have an account?
          <button onClick={onSignUpClick}>
            Sign up
          </button>
        </SwitchModeLink>
      </Content>
    </ContentWrapper>
  )
}

export default LoginModal;
