import React, { useState, useRef } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'

import './autocomplete-styles.css'

import {
  Field,
  Label,
  StyledInput,
} from './styles'

export default function Location({
  setVerifiedLocation,
  initialLocation,
}) {
  const [inputLocation, setInputLocation] = useState(initialLocation) // input value typed
  const inputSuggestion = useRef(null) // setTimeout event to change `inputLocation` value

  const center = { lat: 50.064192, lng: -130.605469 };
  // Create a bounding box with sides ~10km away from the center point
  const defaultBounds = {
    north: center.lat + 0.1,
    south: center.lat - 0.1,
    east: center.lng + 0.1,
    west: center.lng - 0.1
  };

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    onPlaceSelected: (place) => {
      setInputLocation(place.formatted_address)
      setVerifiedLocation(place.formatted_address)
      // If there's a pending event to change input value, cancel it.
      if (inputSuggestion.current) {
        clearTimeout(inputSuggestion.current)
        inputSuggestion.current = null
      }
    },
    options: {
      types: ['(cities)'],
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      strictBounds: false
    }
  })

  return (
    <Field>
      <Label>Where</Label>
      <StyledInput
        value={inputLocation}
        onChange={e => {
          setInputLocation(e.target.value)
        }}
        onFocus={() => {
          setInputLocation('')
          setVerifiedLocation('')
        }}
        onBlur={e => {
          // If there's nothing typed in, ignore suggestions
          if (!e.target.value) {
            return
          }
          // On blur, auto-select the 1st suggestion if a suggestion isn't clicked.
          // However, we won't know if the suggestion is clicked until after blur event,
          // so set verified-location to 1st suggestion, and then suggestions click handler
          // will override later if they click one.
          const suggestions = autocompleteRef.current?.gm_accessors_?.place?.em?.predictions
          if (Array.isArray(suggestions) && suggestions.length) {
            const suggestion = suggestions[0]?.gy
            if (suggestion) {
              setVerifiedLocation(suggestion)
              // If they don't end up clicking a suggestion, change the input value.
              inputSuggestion.current = setTimeout(() => {
                setInputLocation(suggestion)
              }, 100)
            }
          }
        }}
        placeholder="Search location"
        ref={ref}
      />
    </Field>
  );
}
