import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';

const config = {
  apiKey: 'AIzaSyAnjMGKmKaCOHP9VDPHqxULD2gOZCrRU6s',
  authDomain: 'yedding-c8cf7.firebaseapp.com',
  projectId: 'yedding-c8cf7',
  storageBucket: 'yedding-c8cf7.appspot.com',
  messagingSenderId: '128078792205',
  appId: '1:128078792205:web:951547c9378dc83e763744',
  measurementId: 'G-BF7P0BTSHH'
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const storageRef = storage.ref();
const { arrayUnion } = firebase.firestore.FieldValue;
const { arrayRemove } = firebase.firestore.FieldValue;
const functions = firebase.functions();

export { firebase, auth, db, storageRef, arrayUnion, arrayRemove, functions };
