import React, { useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { getVenue } from '../firebase/venues'
import { getUser } from '../store/user'
import { getIsAdmin } from '../components/helpers'

import RequireUser from '../components/RequireUser'
import AdminVenueReview from '../components/SearchVenues/AdminVenueReview'
import Loading from '../components/Loading'

const PageAdminReviewVenue = () => {
  const { id } = useParams()
  const [venue, setVenue] = useState(null)
  const user = useSelector(getUser)
  const isAdmin = getIsAdmin(user)

  useLayoutEffect(() => {
    getVenue({
      id,
      setFetching() {},
      setVenue(venue) {
        if (venue) {
          setVenue(venue)
        }
      },
    })
  }, [])

  if (!venue || !isAdmin) {
    return <Loading />
  }

  return (
    <AdminVenueReview
      venue={venue}
    />
  )
}

export default () => (
  <RequireUser>
    <PageAdminReviewVenue />
  </RequireUser>
)
