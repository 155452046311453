import React from 'react'
import styled from 'styled-components'

import {
  colors,
  NextArrowIcon,
  PrevArrowIcon
} from '../../styles'

const PageLinks = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  gap: 4rem;

  & > * {
    color: ${colors.defaultText};
    font-size: 16px;
    font-weight: 500;
    padding: 8px 16px;
  }

  button {
    background-color: transparent;
    border: none;
    border-radius: 4px;

    &:focus:not(:focus-visible) {
      outline: none;
    }

    &:not([aria-disabled="true"]):hover {
      background-color: ${colors.secondaryBackground};
    }

    &[aria-disabled="true"] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  svg {
    display: block;
    height: 18px;
    width: auto;
    fill: ${colors.defaultText};
  }
`

const PageButton = ({
  disabled = false,
  linkPage,
  setCurrentPage,
  children = null
}) => {
  const label = children || linkPage
  return (
    <button
      aria-disabled={disabled}
      onClick={() => {
        if (disabled) {
          return
        }
        setCurrentPage(linkPage)
      }}
    >
      {label}
    </button>
  )
}

const Pagination = ({
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  if (totalPages <= 1) {
    return null
  }

  return (
    <PageLinks>
      <PageButton
        disabled={currentPage <= 1}
        linkPage={currentPage - 1}
        setCurrentPage={setCurrentPage}
      >
        <PrevArrowIcon />
      </PageButton>

      <div>
        {currentPage}
      </div>

      <PageButton
        disabled={currentPage >= totalPages}
        linkPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      >
        <NextArrowIcon />
      </PageButton>
    </PageLinks>
  )
}

export default Pagination
