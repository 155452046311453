import React from 'react'
import { useSelector } from 'react-redux'

import { getIsSignedIn } from '../../../../store/user'

import LoggedInMenu from './LoggedInMenu'
import LoggedOutMenu from './LoggedOutMenu'


export default function Menu({ omitSearch }) {
  const isSignedIn = useSelector(getIsSignedIn)

  if (isSignedIn) {
    return <LoggedInMenu omitSearch={omitSearch} />
  }

  return (
    <LoggedOutMenu />
  );
}
