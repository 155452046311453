import React from 'react'
import styled, { css } from 'styled-components'

import {
  mobile,
  tablet,
  colors,
} from '../../../styles'

export const SearchWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid ${colors.lightBorder};
  background: ${colors.whiteBackground};
  box-shadow: rgba(0,0,0,0.045) 2px 2px 5px;

  color: ${colors.defaultText};

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: ${mobile}) {
    justify-content: space-between;
  }
`;

export const Field = styled.fieldset`
  padding: 21px 16px;
  border-left: 1px solid ${colors.lightBorder};

  &:first-of-type {
    border-left: none;
    flex-basis: 30%;

    @media (max-width: ${mobile}) {
      flex-basis: auto;
      flex-grow: 1;
    }
  }

  &:last-of-type {
    flex-basis: 20%;
  }

  @media (max-width: ${tablet}) {
    padding: 18px 10px;
  }

  @media (max-width: ${mobile}) {
    padding: 11px 11px;
  }

  ${({ mobileHide }) => mobileHide && css`
    @media (max-width: ${mobile}) {
      display: none;
    }
  `}
`

export const Label = styled.label`
  display: block;
  font-size: 10px;
  line-height: 1;
  color: ${colors.defaultText};
  text-align: left;
`

export const valueStyle = css`
  font-size: 14px;
  line-height: 1;
  color: ${colors.defaultText};
  text-align: left;
`

export const StyledInput = styled.input.attrs({
  type: 'text',
})`
  ${valueStyle}
  display: block;
  border: none;
  outline: none;
  padding: 0;
  margin: 6px 0 0;
  width: 100%;

  &::placeholder {
    color: ${colors.placeholderText};
  }
`

export const DateTrigger = styled.button`
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

export const DateText = styled.div`
  ${valueStyle}
`

export const DateSeperator = styled.div`
  margin: 0 16px;
  height: 30px;
  width: 1px;
  background-color: ${colors.lightBorder};
`

export const SearchButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  padding: 13px;
  background-color: ${colors.secondaryButton};
  border-radius: 50%;
  margin-right: 12px;

  @media (max-width: ${tablet}) {
    margin-right: 4px;
  }

  @media (max-width: ${mobile}) {
    background-color: transparent;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.9;
  }

  svg {
    display: inline-block;
    margin-left: -1px;
    margin-top: -1px;
    height: 18px;
    width: 18px;
    fill: ${colors.whiteBackground};

    @media (max-width: ${mobile}) {
      fill: ${colors.secondaryButton};
    }
  }
`
