import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'

import '../../Shared/AuthModal/authmodal-styles.css'

import {
  colors,
  CloseIcon,
} from '../../styles'
import {
  ContentWrapper,
  Media,
  Content,
  Header,
  Title,
  SubTitle,
  PrimaryLink,
} from '../../Shared/AuthModal/styles'

const CancelContainer = styled.div`
  button {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: none;
    color: ${colors.defaultText};
    text-decoration: underline;
    font-size: 12px;
    line-height: 1;
  }
`

const CancelModal = ({ show, onCancelClick, onCloseClick }) => (
  <Modal
    centered
    className="auth-modal"
    show={show}
    onHide={onCloseClick}
  >
    <ContentWrapper>
      <Media />
      <Content>
        <Header>
          <div>
            <Title>Cancel Reservation?</Title>
            <SubTitle>
              Refunds will be subject to our cancellation policy. The host &amp; customer will receive email confirming the cancellation. And the venue will become available for booking again.
            </SubTitle>
          </div>
          <button onClick={onCloseClick}>
            <CloseIcon />
          </button>
        </Header>
        <PrimaryLink onClick={onCancelClick}>
          Cancel Reservation
        </PrimaryLink>
      </Content>
    </ContentWrapper>
  </Modal>
)

const CancelButton = ({ onCancelClick: onCancel }) => {
  const [showModal, setShowModal] = useState(false)
  const onOpenClick = () => setShowModal(true)
  const onCloseClick = () => setShowModal(false)
  const onCancelClick = () => {
    setShowModal(false)
    onCancel()
  }

  return (
    <CancelContainer>
      <button onClick={onOpenClick}>Cancel</button>
      <CancelModal
        show={showModal}
        onCloseClick={onCloseClick}
        onCancelClick={onCancelClick}
      />
    </CancelContainer>
  )
}

export default CancelButton
