import React from 'react';
import PropTypes from 'prop-types';

const VenuesTable = (props) => {
  const { venues, setVenueVerified, reloadData } = props;
  if (!venues || !venues.length) return <></>;
  return (
    <>
      <h3>Venues</h3>
      <table className="table table-hover table-stripped">
        <thead>
          <tr>
            <td>Id</td>
            <td>User</td>
            <td>Name</td>
            <td>View/Edit Details</td>
            <td>Verified</td>
          </tr>
        </thead>
        <tbody>
          {venues.map((venue) => {
            return (
              <tr key={venue.id}>
                <td>{venue.id}</td>
                <td>{venue.userId}</td>
                <td>{venue.name}</td>
                <td>
                  <a href={`/admin/review/${venue.id}`} target="_blank" rel="noreferrer">
                    View/Edit
                  </a>
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={venue.verified}
                    onChange={() => {
                      setVenueVerified(venue.id, !venue.verified, () => {
                        reloadData();
                      });
                    }}
                  ></input>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

VenuesTable.propTypes = {
  venues: PropTypes.array,
  setVenueVerified: PropTypes.func,
  reloadData: PropTypes.func
};

export default VenuesTable;
