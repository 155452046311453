import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {
  colors,
  notMobile,
  FavoriteIcon,
  FavoritedIcon,
} from '../../styles'

import { addSavedVenue, removeSavedVenue } from '../../../firebase/user'
import { getIsSignedIn, getUser, setUser, setShowSignIn } from '../../../store/user'


export const FavoriteButton = styled.button`
  flex: 0 0 auto;
  margin-left: 8px;
  padding: 7px;
  border: none;
  border-radius: 4px;
  border: 1px solid ${colors.secondaryBackground};
  background-color: ${colors.secondaryBackground};

  &:hover {
    background-color: ${colors.secondaryBackground};
    border-color: ${colors.secondaryIcon};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  ${({ venueIsSelected }) => venueIsSelected && css`
    @media (min-width: ${notMobile}) {
      background-color: ${colors.whiteBackground};

      &:hover {
        background-color: ${colors.whiteBackground};
      }
    }
  `}

  svg {
    display: inline-block;
    width: 18px;
    height: 16px;
  }
`


const FavoriteVenue = ({ venue, venueIsSelected }) => {
  const dispatch = useDispatch()
  const authed = useSelector(getIsSignedIn)
  const userData = useSelector(getUser)
  const setUserData = useCallback(value => {
    dispatch(setUser(value))
  }, [dispatch])

  // only run lookup once on init
  const getIsFavorited = () => authed && Array.isArray(userData.savedVenues) && userData.savedVenues.includes(venue.id)
  // optimistic writes
  const [isEnabled, setIsEnabled] = useState(getIsFavorited)
  // if logs in, recheck if favorited
  useEffect(() => {
    if (authed) {
      setIsEnabled(getIsFavorited())
    }
  }, [authed])

  const onClick = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!authed) {
      dispatch(setShowSignIn())
      return
    }
    if (isEnabled) {
      setIsEnabled(false)
      removeSavedVenue(venue.id, setUserData)
    } else {
      setIsEnabled(true)
      addSavedVenue(venue.id, setUserData)
    }
  }

  return (
    <FavoriteButton
      active={isEnabled}
      venueIsSelected={venueIsSelected}
      onClick={onClick}
    >
      {isEnabled ? (
        <FavoritedIcon />
      ) : (
        <FavoriteIcon />
      )}
    </FavoriteButton>
  )
}

export default FavoriteVenue
