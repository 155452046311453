import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Search from './Search'
import Menu from './Menu'

import { colors, tablet, mobile, LogoIcon } from '../../styles'

const Background = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  padding: 25px 0;
  z-index: 10;

  ${({ omitSearch }) => omitSearch && css`
    padding: 20px 0;
  `}

  @media (max-width: ${tablet}) {
    padding: 20px 0;
  }

  @media (max-width: ${mobile}) {
    padding: 13px 0;
  }
`

const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 44px;
  color: ${colors.defaultText};

  @media (max-width: ${tablet}) {
    padding: 0 24px;
  }

  @media (max-width: ${mobile}) {
    padding: 0 15px;
  }
`;

const LogoWrapper = styled.div`
  flex-shrink: 0;

  svg {
    display: block;
    height: 48px;
    width: auto;
    fill: ${colors.defaultText};
    opacity: 0.9;

    @media (max-width: ${mobile}) {
      height: 30px;
    }
  }
`

const SearchWrapper = styled.div`
  flex-shrink: 1;
  padding: 0 15px;

  @media (max-width: ${mobile}) {
    flex-grow: 1;
    padding: 0 20px;
  }
`

const MenuWrapper = styled.div`
  flex-shrink: 0;
`;

export default function Header({ omitSearch = false }) {
  return (
    <Background omitSearch={omitSearch}>
      <Wrapper>
        <LogoWrapper>
          <Link style={{ alignSelf: 'center' }} to="/">
            <LogoIcon />
          </Link>
        </LogoWrapper>

        {!omitSearch && (
          <SearchWrapper>
            <Search />
          </SearchWrapper>
        )}

        <MenuWrapper>
          <Menu omitSearch={omitSearch} />
        </MenuWrapper>
      </Wrapper>
    </Background>
  );
}
