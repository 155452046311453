import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { colors, mobile, MapIcon, ListIcon, FiltersIcon, AddIcon } from '../../styles'
import { isAnyLocation, useSearchParams } from '../../helpers'

import FiltersModal from './FiltersModal'

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.defaultText};
  margin-bottom: 24px;
`

const LeftContent = styled.div`
  flex: 1 1 auto;
  font-weight: normal;
`

const RightContent = styled.div`
  flex: 0 0 auto;
  position: relative;
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 2px;
`

const VenuesCount = styled.div`
  font-size: 12px;
  color: ${colors.fadedText};
`

const Button = styled.button`
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid ${colors.lightBorder};
  background-color: transparent;
  font-size: 14px;
  margin-left: 1rem;

  &:hover {
    border-color: ${colors.defaultBorder};
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @media (max-width: ${mobile}) {
    .mobile-hide {
      display: none;
    }
  }

  & > span {
    margin-right: 4px;

    @media (max-width: ${mobile}) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    display: inline-block;
    margin-top: -2px;
  }
`

const PrimaryButton = styled.button`
  padding: 6px 10px;
  border-radius: 4px;
  font-weight: 500;
  color: white;
  border: none;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  margin-left: 1rem;

  &:hover {
    opacity: 0.9;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  @media (max-width: ${mobile}) {
    .mobile-hide {
      display: none;
    }
  }

  & > span {
    margin-right: 4px;

    @media (max-width: ${mobile}) {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    display: inline-block;
    margin-top: -3px;
    height: 12px;
    width: 12px;
    fill: ${colors.whiteBackground};
  }
`

const HostAddVenueButton = ({ onClick }) => {
  return (
      <PrimaryButton
        onClick={e => {
          e.preventDefault()
          onClick()
        }}
      >
        <AddIcon />
        <span className="mobile-hide">Add Venue</span>
      </PrimaryButton>
  )
}


const ListTitle = ({
  defaultTitle = "Venues",
  countTitle = "venue",
  count,
  viewMap,
  setViewMap,
  disableMapView = false,
  onAddVenueClick,
}) => {
  const { location } = useSearchParams()
  const title =
    isAnyLocation(location)
    ? defaultTitle
    : `${defaultTitle} near ${location}`
  const [showFilters, setShowFilters] = useState(false)

  return (
    <Container>
      <LeftContent>
        <Title>
          {title}
        </Title>
        <VenuesCount>
          {count} {countTitle}{count === 1 ? '' : 's'}
        </VenuesCount>
      </LeftContent>

      <RightContent>
        {onAddVenueClick && (
          <HostAddVenueButton onClick={onAddVenueClick} />
        )}
        {!disableMapView && (
          <Button
            onClick={e => {
              e.preventDefault()
              setViewMap(!viewMap)
            }}
          >
            {viewMap ? (
              <>
                <ListIcon />
                <span className="mobile-hide">View List</span>
              </>
            ) : (
              <>
                <MapIcon />
                <span className="mobile-hide">View Map</span>
              </>
            )}
          </Button>
        )}
        {/* <Button
          onClick={e => {
            e.preventDefault()
            setShowFilters(value => !value)
          }}
        >
          <FiltersIcon />
        </Button>
        <FiltersModal
          show={showFilters}
          setShow={setShowFilters}
        /> */}
      </RightContent>
    </Container>
  )
}

export default ListTitle
