import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  isAnyLocation,
  makeSearchUrl,
  useSearchParams,
} from '../../../helpers'
import { setRange } from './DatePicker/helpers'

import { SearchIcon } from '../../../styles'
import Location from './Location'
import Guests from './Guests'
import DatePicker from './DatePicker'
import {
  SearchWrapper,
  SearchButton,
} from './styles'


export default function Search() {
  const { location, guests, start: startDate, end: endDate } = useSearchParams()
  const initialLocation = isAnyLocation(location) ? '' : location
  const [verifiedLocation, setVerifiedLocation] = useState(initialLocation) // value submitted
  const [numGuests, setNumGuests] = useState(Number(guests) || '')
  const [dateRange, setDateRange] = useState(setRange(startDate, endDate))

  const history = useHistory();

  const onSubmitClick = async () => {
    try {
      history.push(makeSearchUrl({
        location: verifiedLocation,
        start: dateRange.startDate,
        end: dateRange.endDate,
        guests: numGuests,
      }))
    } catch (e) {
      console.error(e)
    }
  };

  return (
    <SearchWrapper>
      <Location
        setVerifiedLocation={setVerifiedLocation}
        initialLocation={initialLocation}
      />

      <DatePicker
        dateRange={dateRange}
        setDateRange={setDateRange}
      />

      <Guests
        numGuests={numGuests}
        setNumGuests={setNumGuests}
      />

      <SearchButton onClick={onSubmitClick}>
        <SearchIcon />
      </SearchButton>
    </SearchWrapper>
  );
}
