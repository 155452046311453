import React, { useState, useRef } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'

import {
  CloseIcon,
  FacebookIcon,
  GoogleIcon,
} from '../../styles'
import {
  ContentWrapper,
  Media,
  Content,
  Header,
  Title,
  SubTitle,
  Field,
  Label,
  StyledInput,
  TermsField,
  SubmitButton,
  AlternativeButton,
  OrText,
  OrTextBorder,
  SwitchModeLink,
  ErrorMessage,
} from './styles'

import { useAppContext } from '../../../context/state';
import { signUpUser, getUserData } from '../../../firebase/user';
import {
  getIsFetching,
  setIsFetching,
  setShowSignIn,
  close,
} from '../../../store/user'
import { termsOfUse, privacyPolicy, hostAgreement, guestAgreement } from '../../helpers'


const SignupModal = () => {
  const dispatch = useDispatch()
  const onCloseClick = () => dispatch(close())
  const onSignInClick = () => dispatch(setShowSignIn())

  const isFetching = useSelector(getIsFetching)
  const { setUserData } = useAppContext()

  const [socialId, setSocialId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const submitBtn = useRef(null)

  const handleSubmit = e => {
    e.preventDefault()
    if (
      firstName.trim() === ''
      || lastName.trim() === ''
      || email.trim() === ''
      || password.trim() === ''
    ) {
      setError('Please fill out all fields')
    } else {
      dispatch(setIsFetching(true))
      signUpUser(
        { socialId, firstName, lastName, email, password },
        () => {
          getUserData(setUserData)
          dispatch(setIsFetching(false))
          onCloseClick()
        },
        error => {
          dispatch(setIsFetching(false))
          setError(error)
        }
      )
    }
  };

  const responseFacebook = (response) => {
    if (response && response.name) {
      setSocialId(response.id)
      const tokenizedName = response.name.split(' ')
      setFirstName(tokenizedName[0])
      setLastName(tokenizedName[tokenizedName.length - 1])
      setEmail(response.email.toString().trim())
      setPassword('placeholder123')
      setTimeout(() => {
        submitBtn.current.click()
      }, 200)
    }
  }

  const responseGoogle = async (googleData) => {
    if (googleData.profileObj) {
      const data = googleData.profileObj
      setSocialId(data.googleId)
      setFirstName(data.givenName)
      setLastName(data.familyName)
      setEmail(data.email)
      setPassword('placeholder123')
      setTimeout(() => {
        submitBtn.current.click()
      }, 200)
    }
  };

  return (
    <ContentWrapper>
      <Media />
      <Content>
        <Header>
          <div>
            <Title>Sign Up</Title>
            <SubTitle>
              Welcome to Yedding! Select method to sign in:
            </SubTitle>
          </div>
          <button onClick={onCloseClick}>
            <CloseIcon />
          </button>
        </Header>
        <form onSubmit={handleSubmit}>
          <Field>
            <Label>First Name</Label>
            <StyledInput
              type="text"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="Jane"
            />
          </Field>
          <Field>
            <Label>Last Name</Label>
            <StyledInput
              type="text"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Doe"
            />
          </Field>
          <Field>
            <Label>Email</Label>
            <StyledInput
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="janedoe@gmail.com"
            />
          </Field>
          <Field>
            <Label>Password</Label>
            <StyledInput
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="**************"
            />
          </Field>
          <TermsField>
            <input
              type="checkbox"
              onChange={() => {}}
              checked={true}
            />
            <div>
              By creating an account, I agree to Yedding's
              {" "}<a href={termsOfUse} target="_blank">Terms of Service</a>,
              {" "}<a href={guestAgreement} target="_blank">Guest Policy</a>,
              {" "}<a href={hostAgreement} target="_blank">Host Policy</a>,
              {" "}and{" "}<a href={privacyPolicy} target="_blank">Privacy Policy</a>.
            </div>
          </TermsField>
          {!!error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <SubmitButton
            type="submit"
            disabled={isFetching}
            value={isFetching ? 'Loading...' : 'Sign Up'}
          />
        </form>
        <OrText>
          <OrTextBorder style={{ left: 0 }} />
          <OrTextBorder style={{ right: 0 }} />
          OR
        </OrText>
        <GoogleLogin
          style={{ width: '100%', display: 'flex' }}
          clientId="733198051031-bma609juagpsjeoagjt5c6r03rkhfej0.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          render={props => (
            <AlternativeButton {...props}>
              <GoogleIcon />
              Sign Up with Google
            </AlternativeButton>
          )}
        />
        <FacebookLogin
          appId="273134088032579"
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          render={props => (
            <AlternativeButton {...props}>
              <FacebookIcon />
              Sign Up with Facebook
            </AlternativeButton>
          )}
        />
        <SwitchModeLink>
          Already have an account?
          <button onClick={onSignInClick}>
            Sign In
          </button>
        </SwitchModeLink>
      </Content>
    </ContentWrapper>
  )
}

export default SignupModal;
