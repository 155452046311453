import { db } from '.';

export const adminGetUsers = (callback) => {
  const users = db.collection('users');
  users
    .orderBy('registerTime', 'desc')
    .get()
    .then((querySnapshot) => {
      const tempDoc = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      console.log(tempDoc);
      callback(tempDoc);
    });
};

export const adminGetVenues = (callback) => {
  const venues = db.collection('venues');
  venues.get().then((querySnapshot) => {
    const tempDoc = querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    console.log(tempDoc);
    callback(tempDoc);
  });
};

export const setUserVerified = (userId, verified, callback) => {
  db.collection('users')
    .doc(userId)
    .update({ verified })
    .then((doc) => {
      if (callback) callback(doc);
    })
    .catch((e) => {
      alert('There was an error setting user data');
      console.log(e);
    });
};

export const setVenueVerified = (venueId, verified, callback) => {
  db.collection('venues')
    .doc(venueId)
    .update({ verified })
    .then((doc) => {
      if (callback) callback(doc);
    })
    .catch((e) => {
      alert('There was an error setting venue data');
      console.log(e);
    });
};
