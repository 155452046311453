import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'

import Header from '../Shared/NewHeader'
import Footer from '../Shared/NewFooter'
import AuthModal from '../Shared/AuthModal'
import ListTitle from './ListTitle'
import ListView from './ListView'
import DetailView from './DetailView'
import MapView from './MapView'
import { PageWrapper, ContentWrapper } from '../styles'

import { mobile, tablet, colors, bodyStyles } from '../styles'
import { makeSearchUrl } from '../helpers'
import { getSearchResults } from '../../store/search'
import { useSelectVenue } from './hooks'


const MobileWrapper = styled.div`
  ${bodyStyles}
  display: none;

  @media (max-width: ${mobile}) {
    ${({ showDetailView }) => showDetailView && css`
      display: block;
      box-sizing: border-box;
      padding: 24px;
      background-color: ${colors.whiteBackground};
      width: 100%;
      height: auto;
    `}
  }
`

const NonMobileWrapper = styled(PageWrapper)`
  @media (max-width: ${mobile}) {
    ${({ showDetailView }) => showDetailView && css`
      display: none;
    `}
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-basis: 50%;
  padding: 40px 44px 42px;

  @media (max-width: ${tablet}) {
    padding: 40px 24px 42px;
  }

  @media (max-width: ${mobile}) {
    padding: 30px 15px 42px;
    flex-basis: 100%;
    height: auto;
  }
`

const DetailWrapper = styled.div`
  border-left: 1px solid ${colors.lightBorder};
  flex-basis: 50%;
  padding: 40px 44px 42px;

  @media (max-width: ${tablet}) {
    padding: 40px 24px 42px;
  }

  @media (max-width: ${mobile}) {
    display: none;
  }
`

const HeaderCenterText = styled.div`
  ${bodyStyles}
  text-align: center;
  color: ${colors.fadedText};
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
`


function SearchVenues({
  disableMapView = false,
  disableBookButton = false,
  defaultTitle,
  countTitle,
  noResultsMessage = 'No venues matched the search criteria',
  makeUrl = makeSearchUrl,
}) {
  const scrollRef = useRef(null)
  const [viewMap, setViewMap] = useState(false)
  const searchResults = useSelector(getSearchResults)
  const {
    selected,
    hasSelection,
    setSelectedVenue,
    clearSelectedVenue,
    pageResults,
    allResults,
    hasResults,
    totalPages,
    currentPage,
    setCurrentPage,
  } = useSelectVenue({
    searchResults,
    makeUrl,
    scrollRef,
  })

  const detailView = hasResults && (
    <DetailView
      venue={selected}
      bookNow={!disableBookButton}
      close={clearSelectedVenue}
    />
  )

  return (
    <>
    <MobileWrapper showDetailView={hasSelection}>
      {detailView}
    </MobileWrapper>
    <NonMobileWrapper showDetailView={hasSelection}>
      <Header />
      <ContentWrapper>
        {hasResults ? (
          <>
            <ListWrapper ref={scrollRef}>
              <ListTitle
                defaultTitle={defaultTitle}
                countTitle={countTitle}
                count={allResults.length}
                viewMap={viewMap}
                setViewMap={setViewMap}
                disableMapView={disableMapView}
              />
              {viewMap ? (
                <MapView
                  center={{ lat: Number(allResults[0].location.lat), lng: Number(allResults[0].location.lng) }}
                  selectedVenue={selected}
                  setSelectedVenue={setSelectedVenue}
                  venues={allResults}
                  zoom={12}
                />
              ) : (
                <ListView
                  venueList={pageResults}
                  selected={selected}
                  setSelectedVenue={setSelectedVenue}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </ListWrapper>
            <DetailWrapper>
              {detailView}
            </DetailWrapper>
          </>
        ) : (
          <HeaderCenterText>
            <div>{noResultsMessage}</div>
          </HeaderCenterText>
        )}
      </ContentWrapper>
      <Footer />
      <AuthModal />
    </NonMobileWrapper>
    </>
  )
}

export default SearchVenues
