import React from 'react'
import styled from 'styled-components'

import { colors } from '../../styles'


export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const MediaContent = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  background-image: url(/auth-modal-wedding.jpg);
  height: 150px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-position: center top;
`
export const Media = () => {
  return null
}

export const Content = styled.div`
  padding: 24px;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  & > div {
    flex-grow: 1;
    flex-shrink: 1;
  }

  & > button {
    flex-grow: 0;
    flex-shrink: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: -5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SubTitle = styled.div`
  color: ${colors.fadedText};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`
export const Field = styled.fieldset`
  padding: 16px;
  border: 1px solid ${colors.lightBorder};
  border-radius: 6px;
  margin-bottom: 16px;
`

export const TermsField = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  font-weight: 400;

  a {
    color: ${colors.defaultText};
    text-decoration: underline;
  }
`

export const Label = styled.label`
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: ${colors.defaultText};
  text-align: left;
`

export const StyledInput = styled.input`
  font-size: 14px;
  line-height: 1;
  color: ${colors.defaultText};
  text-align: left;

  display: block;
  border: none;
  outline: none;
  padding: 0;
  margin: 8px 0 0;
  width: 100%;

  &::placeholder {
    color: ${colors.placeholderText};
  }
`

export const ForgotPasswordLink = styled.div`
  text-align: right;
  margin-bottom: 24px;

  input[type="button"] {
    color: ${colors.fadedText};
    font-size: 12px;
    font-weight: 500;
    background-color: transparent;
    padding: 0;
    padding-bottom: 2px;
    border: none;
    border-bottom: 1px solid ${colors.fadedText};
    line-height: 1;
  }
`

export const SubmitButton = styled.input`
  font-weight: 500;
  color: white;
  background-color: ${colors. primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 0;
  width: 100%;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    opacity: 0.9;
  }
`

export const PrimaryLink = styled.button`
  display: block;
  font-weight: 500;
  color: white;
  text-decoration: none;
  background-color: ${colors. primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 0;
  width: 100%;
  border: none;
  text-align: center;

  &:hover, &:focus {
    color: white;
    text-decoration: none;
    opacity: 0.9;
  }
`

export const AlternativeButton = styled.button`
  font-weight: 500;
  color: ${colors.defaultText};
  background-color: ${colors.whiteBackground};
  border: 1px solid ${colors.mediumBorder};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 0;
  width: 100%;
  margin-top: 16px;

  svg {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 12px;
    margin-top: -3px;
  }
`

export const OrText = styled.div`
  margin-top: 24px;
  position: relative;
  color: ${colors.placeholderText};
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.12px;
  margin-bottom: 8px;
`

export const OrTextBorder = styled.div`
  position: absolute;
  top: 49%;
  height: 1px;
  width: 143px;
  background-color: ${colors.mediumBorder};
`

export const SwitchModeLink = styled.div`
  margin-top: 24px;
  text-align: center;
  color: ${colors.fadedText};
  font-size: 14px;
  font-weight: 400;
  line-height: 1;

  button {
    margin-left: 5px;
    color: ${colors. primaryButton};
    font-weight: 600;
    background-color: transparent;
    padding: 0;
    padding-bottom: 2px;
    border: none;
    border-bottom: 1px solid ${colors. primaryButton};
  }
`

export const ErrorMessage = styled.div`
  margin: -5px 0.833em 16px;
  color: red;
  text-align: center;
`

export const CheckList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 28px;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.defaultText};

  & > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;

    &.completed {
      svg {
        fill: ${colors.primaryIcon};
      }
    }
  }

  svg {
    width: 22px;
    height: 22px;
    display: inline-block;
    fill: ${colors.mediumBorder};
    margin-top: -2px;
  }
`
