/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import styled from 'styled-components';

const CarouselImg = styled.img`
  max-height: 50vh;
  object-fit: cover;
  border-radius: 10px;
`;


class ImageCarousel extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      index: this.props.defaultIndex || 0,
      direction: null
    };
  }

  handleSelect(selectedIndex) {
    this.setState({
      index: selectedIndex
      // direction: e.direction,
    });
  }

  render() {
    const { index, direction } = this.state;

    return (
      <Carousel activeIndex={index} direction={direction} onSelect={this.handleSelect} className="carousel">
        {this.props.images.map((item) => {
          return (
            <Carousel.Item key={item}>
              <CarouselImg className="d-block w-100" src={item} alt="venue image" />
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}

export default ImageCarousel;
