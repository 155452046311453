import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {
  updateUserProfile,
  updateUserEmail,
  updateUserPassword,
} from '../../firebase/user'
import {
  getIsSaving,
  setIsSaving,
  getUser,
  setUser,
} from '../../store/user'
import { mobile, tablet, colors } from '../styles'

import Header from '../Shared/NewHeader'
import Footer from '../Shared/NewFooter'
import AuthModal from '../Shared/AuthModal'
import {
  PageWrapper,
  ContentWrapper,
  ColumnContent,
  Field,
  Label,
  Input,
} from '../styles'

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 12px;
`

const ButtonWrapper = styled.div`
  padding-top: 24px;
  text-align: right;
  position: relative;
`

const SubmitButton = styled.input`
  font-weight: 500;
  color: white;
  background-color: ${colors. primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 40px;
  width: max-content;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`

export const ErrorMessage = styled.div`
  position: absolute;
  top: -2px;
  right: 0;
  color: red;
  font-size: 12px;
  font-weight: 500;
`

function Profile() {
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const isSaving = useSelector(getIsSaving)
  const [error, setError] = useState('')
  const [email, setEmail] = useState(user.email)
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber)

  const [firstName, setFirstName] = useState(user.firstName)
  const [lastName, setLastName] = useState(user.lastName)

  const [currentPassword, setCurrentPassword] = useState('')
  const [nextPassword, setNextPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const nextProps = {
    email: String(email || '').trim(),
    phoneNumber: String(phoneNumber || '').trim(),
    firstName: String(firstName || '').trim(),
    lastName: String(lastName || '').trim(),
    currentPassword: String(currentPassword || ''),
    nextPassword: String(nextPassword || ''),
    confirmPassword: String(confirmPassword || ''),
  }

  const onSubmit = async e => {
    e.preventDefault()
    e.stopPropagation()
    setError('')

    const { email, phoneNumber, firstName, lastName } = nextProps
    if (!email || !firstName || !lastName) {
      setError('Name & email are required fields')
      return
    }

    if (nextPassword && nextPassword !== confirmPassword) {
      setError('New passwords do not match')
      return
    }

    dispatch(setIsSaving(true))

    if (email !== user.email) {
      try {
        console.log('updating email')
        await new Promise((done, reject) => {
          updateUserEmail(email, done, reject)
        })
      } catch (e) {
        setError(e)
        dispatch(setIsSaving(false))
        return
      }
    }

    // NOTE: email is also a property of the user object
    if (
      email !== user.email || phoneNumber !== user.phoneNumber
      || firstName !== user.firstName || lastName !== user.lastName
    ) {
      try {
        console.log('updating names')
        await new Promise((done, reject) => {
          updateUserProfile({ email, phoneNumber, firstName, lastName }, done, reject)
        })
      } catch (e) {
        setError(e)
        dispatch(setIsSaving(false))
        return
      }
    }

    if (nextPassword && currentPassword) {
      try {
        console.log('updating password')
        await new Promise((done, reject) => {
          updateUserPassword({ email, currentPassword, nextPassword }, done, reject)
        })
      } catch (e) {
        setError(e)
        dispatch(setIsSaving(false))
        return
      }
      setCurrentPassword('')
      setNextPassword('')
      setConfirmPassword('')
    }

    dispatch(setIsSaving(false))
  }

  return (
    <PageWrapper>
      <Header />
      <ContentWrapper>
        <ColumnContent>
          <div>
            <Title>User Profile</Title>
            <Field>
              <Label>First Name</Label>
              <Input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
            </Field>
            <Field>
              <Label>Last Name</Label>
              <Input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
            </Field>
          </div>
          <div>
            <Title>Contact Information</Title>
            <Field>
              <Label>Email Address</Label>
              <Input type="text" value={email} onChange={e => setEmail(e.target.value)} />
            </Field>
            <Field>
              <Label>Phone Number</Label>
              <Input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            </Field>
          </div>
          <div>
            <Title>Change Password</Title>
            <Field>
              <Label>Current Password</Label>
              <Input
                type="password"
                placeholder="•••••••••••"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
              />
            </Field>
            <Field>
              <Label>New Password</Label>
              <Input
                type="password"
                placeholder="•••••••••••"
                value={nextPassword}
                onChange={e => setNextPassword(e.target.value)}
              />
            </Field>
            <Field>
              <Label>Confirm Password</Label>
              <Input
                type="password"
                placeholder="•••••••••••"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </Field>
            <ButtonWrapper>
              {!!error && (
                <ErrorMessage>{error}</ErrorMessage>
              )}
              <SubmitButton
                type="button"
                value={isSaving ? 'Saving...' : 'Save Changes'}
                disabled={isSaving}
                onClick={onSubmit}
              />
            </ButtonWrapper>
          </div>
        </ColumnContent>
      </ContentWrapper>
      <Footer />
      {/* NOTE: `AuthModal` is needed for the "Become a Host" link */}
      <AuthModal />
    </PageWrapper>
  )
}

export default Profile