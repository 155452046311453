import React from 'react'
import styled from 'styled-components'

import { colors, CloseIcon } from '../../styles'

const Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 44px;
  right: 0;
  width: 437px;
  padding: 32px;
  box-sizing: border-box;
  border: 1px solid ${colors.lightBorder};
  border-radius: 6px;
  background-color: ${colors.whiteBackground};
  box-shadow: rgba(0,0,0,0.1) 0 4px 12px 0;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid ${colors.lightBorder};

  div {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 6px 2px;
    margin: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

const FiltersModal = ({ show, setShow }) => {
  if (!show) return null

  return (
    <Wrapper>
      <Title>
        <div>Filters</div>
        <button
          onClick={() => setShow(false)}
        >
          <CloseIcon />
        </button>
      </Title>
    </Wrapper>
  )
}

export default FiltersModal