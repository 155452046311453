import moment from 'moment'

// Turn date strings into date objects
const makeDateObject = day => {
  const dateArr = [...day.split('-')];
  dateArr[1] -= 1;
  return new Date(...dateArr);
}

const makeDefaultDate = () => {
  return moment().add(1, 'month').set('date', 1)
}

const hasNoDate = date => {
  return date === 'none' || !date
}

export const setRange = (startDate, endDate) => {
  const isMissingStart = hasNoDate(startDate)
  const isMissingEnd = hasNoDate(endDate)
  if (!isMissingStart) {
    startDate = makeDateObject(startDate)
  }
  if (!isMissingEnd) {
    endDate = makeDateObject(endDate)
  }
  // If missing only 1 date, make the dates the same.
  // If missing both dates, default to next month on the 1st.
  if (isMissingStart && !isMissingEnd) {
    startDate = endDate
  } else if (isMissingEnd && !isMissingStart) {
    endDate = startDate
  } else if (isMissingStart && isMissingEnd) {
    const initialDate = makeDefaultDate().toDate()
    startDate = endDate = initialDate
  }
  return {
    startDate,
    endDate,
  }
};

export const prettyDate = value => {
  if (hasNoDate(value)) {
    value = makeDefaultDate()
  } else {
    value = moment(value)
  }
  return value.format('MM/DD/YYYY')
}

// Venue's have an array of reserved dates. The dates are in reverse order
// so that we can exit once we reach the past.
export const makeDisabledDates = venue => {
  let { bookedDates, excludedDates } = venue || {}
  bookedDates = Array.isArray(bookedDates) ? bookedDates : []
  excludedDates = Array.isArray(excludedDates) ? excludedDates : []
  let disabledDates = []
  // fix sort & remove duplicates if both arrays have values
  if (bookedDates.length && excludedDates.length) {
    disabledDates = bookedDates.concat(excludedDates)
    disabledDates.sort((a, b) => {
      if (a < b) return 1
      if (b < a) return -1
      return 0
    })
    disabledDates = disabledDates.filter((value, i, arr) => (
      i === 0 || arr[i - 1] !== value
    ))
  } else if (bookedDates.length) {
    disabledDates = bookedDates
  } else if (excludedDates.length) {
    disabledDates = excludedDates
  } else {
    // there's no dates, so stop prematurely
    return []
  }

  const today = moment()
  const index = disabledDates.findIndex(date => {
    return today.isAfter(date)
  })
  disabledDates = (
    index === -1 ? disabledDates : disabledDates.slice(0, index)
  ).map(date => (
    new Date(`${date}T00:00:00.000-06:00`)
  ))

  return disabledDates
}
