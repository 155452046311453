import { configureStore, combineReducers } from '@reduxjs/toolkit'

import hostedVenue from './hosted-venue'
import search from './search'
import user from './user'

const combinedReducers = combineReducers({
  hostedVenue,
  search,
  user,
})


const store = configureStore({
  reducer: combinedReducers
})

// for debugging
if (window.location.hostname === 'localhost' || window.location.search.includes('DEBUG_MODE')) {
  window._store = store
}

export default store
