import { createSlice, createSelector } from '@reduxjs/toolkit'

const initialState = {
  form: {
    name: '',
    description: '',
    setting: 'outdoor',
    venueType: 'home',
    address_street: '',
    address_city: '',
    address_state: '',
    address_zip: '',
    address_country: 'United States of America',
    additionalNotes: '',
    amenities: [],
    dayPrice: '',
    maxGuests: '',
    cancellationPolicy: 'flexible', // flexible, moderate, strict
    photos: [], // [{ url, ref }]
    disabled: false,
    verified: false,
    excludedDates: [],
  },
  formErrors: {},
  uploadError: '',
  geoErrors: false,
  saveError: false,
  isSaving: false,
  isUploading: false,
}

const search = createSlice({
  name: 'hostedVenue',
  initialState,
  reducers: {
    // useful if editing an existing record
    setInitialState(state, action) {
      state.formErrors = {} // reset
      state.uploadError = ''
      state.geoErrors = false
      state.saveError = false
      state.isSaving = false
      state.isUploading = false
      // start form off empty
      const { form: emptyForm } = initialState
      state.form = { ...emptyForm }
      // add venue state if fields overlap with form
      const venueState = action.payload
      if (venueState) {
        for (let name in emptyForm) {
          // grab address from existing venue, if exists
          if (name.startsWith('address_')) {
            const field = name.replace('address_', '')
            if (venueState.address && venueState.address[field]) {
              state.form[name] = venueState.address[field]
            }
          } else if (venueState.hasOwnProperty(name)) {
            state.form[name] = venueState[name]
          }
        }
      }
    },
    setFormValue(state, action) {
      Object.assign(state.form, action.payload)
      // remove validation error if exists
      for (let name in action.payload) {
        if (state.formErrors[name]) {
          delete state.formErrors[name]
        }
        if (name === 'photos') {
          state.uploadError = ''
          state.isUploading = false
        }
        if (name.startsWith('address') && state.geoErrors) {
          state.geoErrors = false
        }
      }
    },
    setFormErrors(state, action) {
      state.formErrors = action.payload || {}
      state.isSaving = false
    },
    setUploadError(state, action) {
      state.uploadError = action.payload || ''
      state.isUploading = false
    },
    setGeoErrors(state, action) {
      state.geoErrors = action.payload === true
      state.isSaving = false
    },
    setSaveError(state, action) {
      state.saveError = action.payload === true
      state.isSaving = false
    },
    setIsSaving(state, action) {
      state.isSaving = action.payload === true
      state.formErrors = {}
      state.uploadError = ''
      state.geoErrors = false
      state.saveError = false
    },
    setIsUploading(state, action) {
      state.isUploading = action.payload === true
    },
  },
})

export default search.reducer

export const {
  setInitialState,
  setFormValue,
  setFormErrors,
  setUploadError,
  setGeoErrors,
  setSaveError,
  setIsSaving,
  setIsUploading,
} = search.actions


////////////////////////////
//   Selector Functions   //
////////////////////////////

const selectSelf = state => state.hostedVenue

export const getForm = createSelector(selectSelf, ({ form }) => form )
export const getFormErrors = createSelector(selectSelf, ({ formErrors }) => formErrors )
export const getUploadError = createSelector(selectSelf, ({ uploadError }) => uploadError )
export const getGeoErrors = createSelector(selectSelf, ({ geoErrors }) => geoErrors )
export const getSaveError = createSelector(selectSelf, ({ saveError }) => saveError )
export const getIsSaving = createSelector(selectSelf, ({ isSaving }) => isSaving )
export const getIsUploading = createSelector(selectSelf, ({ isUploading }) => isUploading )
