import React, { useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { startReservedResults } from '../firebase/venues'
import { makeReservationsUrl } from '../components/helpers'
import { getIsFetching, setIsFetching, setSearchResults } from '../store/search'
import { getUser } from '../store/user'

import RequireUser from '../components/RequireUser'
import SearchVenues from '../components/SearchVenues'
import Loading from '../components/Loading'

const PageReservedVenues = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(getIsFetching)
  const user = useSelector(getUser)

  useLayoutEffect(() => {
    startReservedResults({
      setFetching(value) {
        dispatch(setIsFetching(value))
      },
      setSearchResults(results) {
        dispatch(setSearchResults(results))
      },
      user
    })
  }, [])

  if (isFetching) {
    return <Loading />
  }

  return (
    <SearchVenues
      disableMapView
      disableBookButton
      defaultTitle="Reservations"
      countTitle="reservation"
      noResultsMessage='No reservations found'
      makeUrl={makeReservationsUrl}
    />
  )
}

export default () => (
  <RequireUser>
    <PageReservedVenues />
  </RequireUser>
)
