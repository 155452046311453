import React from 'react'
import styled from 'styled-components'

import Header from '../Shared/NewHeader'
import Footer from '../Shared/NewFooter'
import AuthModal from '../Shared/AuthModal'
import Search from '../Shared/NewHeader/Search'

import { bodyStyles, mobile } from '../styles'

const Background = styled.div`
  ${bodyStyles}
  background-image: url(/home-image.jpg);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

const SearchWrapper = styled.div`
  max-width: 661px;
  margin: 0 auto;
  padding: 200px 21px;
  box-sizing: border-box;

  @media (max-width: ${mobile}) {
    padding: 120px 21px;
    height: calc(100vh - 133px);
  }
`


function Home() {
  return (
    <Background>
      <Header omitSearch />
      <SearchWrapper>
        <Search />
      </SearchWrapper>
      <Footer />
      <AuthModal />
    </Background>
  )
}

export default Home
