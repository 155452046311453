import React, { useState, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getVenue } from '../firebase/venues'
import { getIsFetching, setIsFetching } from '../store/search'

import Confirmation from '../components/Confirmation'
import Loading from '../components/Loading'

const PageConfirmation = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const id = query.get('venue')
  const startDate = query.get('start')
  const endDate = query.get('end')

  const [venue, setVenue] = useState(null)
  const dispatch = useDispatch()
  const isFetching = useSelector(getIsFetching)

  useLayoutEffect(() => {
    // if they refresh the page, redirect to home page
    if (!id) {
      window.location.href = '/'
      return
    }
    // grab venue from firebase
    getVenue({
      id,
      setFetching(value) {
        dispatch(setIsFetching(value))
      },
      setVenue(venue) {
        if (venue) {
          setVenue(venue)
        }
        window.history.replaceState({}, null, window.location.pathname)
      },
    })
  }, [])

  if (isFetching || !venue) {
    return <Loading />
  }

  return (
    <Confirmation
      venue={venue}
      startDate={startDate}
      endDate={endDate}
    />
  )
};

export default PageConfirmation
