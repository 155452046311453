import React from 'react'
import styled from 'styled-components'

import useBookVenue from '../../../hooks/useBookVenue'
import {
  tablet,
  mobile,
  colors,
} from '../../styles'


const CardBookContent = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${mobile}) {
    margin-top: 16px;
    align-items: center;
  }
`

const CardCostText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  flex: 1 1 auto;

  span {
    font-size: 14px;
  }
`

const BookButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 16px;
  border: none;

  @media (max-width: ${tablet}) {
    padding: 9px 13px;
  }

  &:hover {
    opacity: 0.9;
  }
`

const TooltipBox = styled.div`
  position: absolute;
  visibility: hidden;
  transition: visibility 0.5s, ease-in-out;
`

const TooltipCard = styled.div`
  position: relative;

  & ${BookButton}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: #59686f;
    width: 100px;
    padding: 8px 8px;
    border-radius: 4px;
  }
`


const BookNow = ({ venue }) => {
  const onBookClick = useBookVenue(venue)

  return (
    <CardBookContent>
      <CardCostText>
        ${Number(venue.dayPrice.replace('$', '')).toLocaleString()}
        <span> / day</span>
      </CardCostText>
      <TooltipCard>
        <BookButton onClick={onBookClick}>
          Book Now
        </BookButton>
      </TooltipCard>
    </CardBookContent>
  );
};

export default BookNow
