import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker.jsx';

function MapView({
  venues,
  selectedVenue,
  setSelectedVenue,
  center,
  zoom,
}) {
  return (
    // Important! Always set the container height explicitly
    <div style={{ maxHeight: '85vh', height: '560px', width: '100%' }}>
      <GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }} defaultCenter={center} defaultZoom={zoom}>
        {venues.map(venue => (
          <MapMarker
            key={venue.id}
            lat={venue.location.lat}
            lng={venue.location.lng}
            venue={venue}
            selectedVenue={selectedVenue}
            setSelectedVenue={setSelectedVenue}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
}

MapView.defaultProps = {
  center: {
    lat: 59.95,
    lng: 30.33
  },
  zoom: 11
};

MapView.propTypes = {
  venues: PropTypes.array,
  selectedVenue: PropTypes.object,
  setSelectedVenue: PropTypes.func,
  center: PropTypes.object,
  zoom: PropTypes.number,
};

export default MapView;
