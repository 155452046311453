import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getUser,
  setShowBecomeHost,
  getIsValidHost,
  getHasHostVenues,
} from '../../../../store/user'
import {
  ProfileIcon,
  CalendarIcon,
  FavoritesIcon,
  VenueIcon,
  SettingsIcon,
  CloseIcon,
} from '../../../styles'
import {
  // modal
  Modal,
  ModalUnderlay,
  ModalHeader,
  ModalName,
  ModalEmail,
  ModalDivider,
  ModalLinks,
  ModalLink,
  ModalHostButton,
  ModalLogoutButton,
} from './styles'


export default function LoggedInModal({ setIsOpen, omitSearch }) {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const hasVenues = useSelector(getHasHostVenues)
  const isValidHost = useSelector(getIsValidHost)

  return (
    <>
      <Modal omitSearch={omitSearch}>
        <ModalHeader>
          <div>
            <ModalName>{user.firstName || ''} {user.lastName || ''}</ModalName>
            <ModalEmail>{user.email}</ModalEmail>
          </div>
          <div>
            <button onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </button>
          </div>
        </ModalHeader>
        <ModalDivider />
        <ModalLinks>
          <ModalLink to="/profile/">
            <ProfileIcon />
            <span>Profile</span>
          </ModalLink>
          <ModalLink to="/reservations/">
            <CalendarIcon />
            <span>Reservations</span>
          </ModalLink>
          <ModalLink to="/favorites/">
            <FavoritesIcon />
            <span>Favorite Venues</span>
          </ModalLink>
          {hasVenues && (
            <ModalLink to="/host/venues/">
              <VenueIcon />
              <span>Manage Venues</span>
            </ModalLink>
          )}
          {isValidHost ? (
            <>
              <ModalLink to="/host/reservations/">
                <CalendarIcon />
                <span>Hosted Reservations</span>
              </ModalLink>
              <ModalLink to="/host/stripe/">
                <SettingsIcon />
                <span>Host Payout Settings</span>
              </ModalLink>
            </>
          ) : (
            <ModalHostButton onClick={() => {
              setIsOpen(false)
              dispatch(setShowBecomeHost(true))
            }}>
              <VenueIcon />
              <span>Become a Host</span>
            </ModalHostButton>
          )}
        </ModalLinks>
        <ModalDivider />
        <ModalLogoutButton to="/logout">
          Log Out
        </ModalLogoutButton>
      </Modal>
      <ModalUnderlay
        role="dialog"
        onClick={() => setIsOpen(false)}
      />
    </>
  )
}