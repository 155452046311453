import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useSearchParams } from '../helpers'

export function useSelectVenue({
  searchResults: {
    venues,
    pageSize,
    totalPages,
    hasResults,
  },
  makeUrl,
  scrollRef,
}) {
  const history = useHistory()
  const searchParams = useSearchParams()
  const { id, page: searchPage } = searchParams

  const selectedVenue = id ? venues.find(venue => (
    venue && venue.id === id
  )) : null
  const hasSelection = !!selectedVenue
  const selectedVenueIndex = hasSelection ? venues.indexOf(selectedVenue) : -1

  // If venue is selected, the initial page should be the venue's page. Or if the URL has a specific page, start there.
  const initialPage = selectedVenueIndex < 0 ? searchPage : Math.ceil((selectedVenueIndex + 1) / pageSize)
  const [currentPage, _setCurrentPage] = useState(initialPage)

  const startIndex = (currentPage - 1) * pageSize
  const pageResults = venues.slice(startIndex, startIndex + pageSize)
  const selected = selectedVenue || pageResults[0]

  const setCurrentPage = page => {
    _setCurrentPage(page)
    // scroll list to top
    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollTo(0,0)
    }
    // update URL
    history.replace(makeUrl({
      ...searchParams,
      page,
      id: null,
    }))
  }

  const setSelectedVenue = id => {
    history.replace(makeUrl({
      ...searchParams,
      page: currentPage,
      id,
    }))

    if (id) {
      window.scrollTo(0,0)
    }
  }

  const clearSelectedVenue = () => {
    setSelectedVenue('')
  }

  return {
    selected,
    hasSelection,
    setSelectedVenue,
    clearSelectedVenue,
    pageResults,
    allResults: venues,
    hasResults,
    totalPages,
    currentPage,
    setCurrentPage,
  }
}
