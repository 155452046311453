import React, { useRef, useState, useLayoutEffect } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Header from '../../Shared/NewHeader'
import Footer from '../../Shared/NewFooter'
import AuthModal from '../../Shared/AuthModal'
import ListTitle from '../ListTitle'
import ListView from '../ListView'
import DetailForm from './DetailForm'
import { PageWrapper, ContentWrapper } from '../../styles'

import { mobile, tablet, colors, bodyStyles } from '../../styles'
import { makeSearchUrl } from '../../helpers'
import { getSearchResults } from '../../../store/search'
import { setInitialState } from '../../../store/hosted-venue'
import { useSelectVenue } from '../hooks'

const SimplifiedContentWrapper = styled(ContentWrapper)`
  overflow: auto;

  & > div {
    overflow: visible;
  }
`

const EditContentWrapper = styled(ContentWrapper)`
  flex-wrap: nowrap;
`

const MobileWrapper = styled.div`
  ${bodyStyles}
  display: none;

  @media (max-width: ${mobile}) {
    ${({ showForm }) => showForm && css`
      display: block;
      box-sizing: border-box;
      padding: 24px;
      background-color: ${colors.whiteBackground};
      width: 100%;
      height: auto;
    `}
  }
`

const NonMobileWrapper = styled(PageWrapper)`
  @media (max-width: ${mobile}) {
    ${({ showForm }) => showForm && css`
      display: none;
    `}
  }
`

const ListWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-basis: max-content;
  padding: 40px 44px 42px;
  box-sizing: border-box;

  @media (max-width: ${tablet}) {
    padding: 40px 24px 42px;
  }

  @media (max-width: ${mobile}) {
    padding: 30px 15px 42px;
    flex-basis: 100%;
    height: auto;
  }
`

const DetailWrapper = styled.div`
  flex-shrink: 1;
  border-left: 1px solid ${colors.lightBorder};
  padding: 40px 44px 42px;
  min-width: 460px;

  &:first-child {
    border-left: none;
    flex-basis: 60%;

    @media (max-width: 1300px) {
      flex-basis: 70%;
    }
  }
  &:not(:first-child) {
    flex-grow: 1;
  }

  @media (max-width: ${tablet}) {
    padding: 40px 24px 42px;
  }

  @media (max-width: ${mobile}) {
    display: none;
  }
`


function HostedVenues({
  makeUrl = makeSearchUrl,
}) {
  const scrollRef = useRef(null)
  const searchResults = useSelector(getSearchResults)
  const [showNewForm, setShowNewForm] = useState(false)
  const {
    selected,
    hasSelection,
    setSelectedVenue,
    clearSelectedVenue,
    pageResults,
    allResults,
    hasResults,
    totalPages,
    currentPage,
    setCurrentPage,
  } = useSelectVenue({
    searchResults,
    makeUrl,
    scrollRef,
  })
  const dispatch = useDispatch()

  // If the page loaded with a venue already selected, get form ready
  useLayoutEffect(() => {
    if (hasSelection) {
      dispatch(setInitialState(selected))
    }
  }, [])

  // Show simplified form if they don't have any venues yet
  if (!hasResults) {
    const detailView = (
      <DetailForm
        selected={selected}
      />
    )
    return (
      <>
      <MobileWrapper showForm>
        {detailView}
      </MobileWrapper>
      <NonMobileWrapper showForm>
        <Header />
        <SimplifiedContentWrapper>
          <DetailWrapper>
            {detailView}
          </DetailWrapper>
        </SimplifiedContentWrapper>
        <Footer />
        <AuthModal />
      </NonMobileWrapper>
      </>
    )
  }

  // click to add a new venue
  const onAddVenueClick = !showNewForm ? () => {
    dispatch(setInitialState()) // reset form to clean slate
    setShowNewForm(true)        // open new form
    if (hasSelection) {         // close edit form, if active
      clearSelectedVenue()
    }
  } : null
  // click to edit a venue
  const setSelected = id => {
    const venueState = allResults.find(venue => (
      venue && venue.id === id
    ))
    if (venueState) {
      dispatch(setInitialState(venueState))  // set form state to venue
      setShowNewForm(false)     // close new form, if active
      setSelectedVenue(id)      // open edit form
    }
  }
  // cancel new or edit venue
  const clearSelected = () => {
    if (hasSelection) {
      clearSelectedVenue()
    } else if (showNewForm) {
      setShowNewForm(false)
    }
  }

  const detailView = (hasSelection || showNewForm) && (
    <DetailForm
      isEditMode={hasSelection}
      id={hasSelection ? selected.id : null}
      clearSelected={clearSelected}
    />
  )

  return (
    <>
    <MobileWrapper showForm={hasSelection}>
      {detailView}
    </MobileWrapper>
    <NonMobileWrapper showForm={hasSelection}>
      <Header />
      <EditContentWrapper>
        <ListWrapper ref={scrollRef}>
          <ListTitle
            defaultTitle="Hosted Venues"
            count={allResults.length}
            disableMapView
            onAddVenueClick={onAddVenueClick}
          />
          <ListView
            venueList={pageResults}
            selected={hasSelection ? selected : null}
            setSelectedVenue={setSelected}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            isHostView
          />
        </ListWrapper>
        <DetailWrapper>
          {detailView}
        </DetailWrapper>
      </EditContentWrapper>
      <Footer />
      <AuthModal />
    </NonMobileWrapper>
    </>
  )
}

export default HostedVenues
