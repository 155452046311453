import React from 'react';
import styled from 'styled-components';

import {
  mobile,
  tablet,
  colors,
  LogoFacebookIcon,
  LogoInstagramIcon,
  LogoTiktokIcon,
  LogoLinkedinIcon,
} from '../../styles';
import { companyEmail, companyPhone, privacyPolicy, termsOfUse } from '../../helpers'

const Background = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;

  @media (max-width: ${mobile}) {
    position: relative;
  }
`

const Wrapper = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 44px;
  font-size: 14px;
  color: ${colors.defaultText};

  a {
    color: ${colors.defaultText};

    @media (max-width: ${mobile}) {
      font-weight: 500;
    }
  }

  @media (max-width: ${tablet}) {
    padding: 0 24px;
  }

  @media (max-width: ${mobile}) {
    height: auto;
    flex-direction: column;
    padding: 15px;
    font-size: 12px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;

  @media (max-width: ${mobile}) {
    width: 100%;
    justify-content: flex-start;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const MobileHide = styled.span`
  @media (max-width: ${mobile}) {
    display: none;
  }
`

const MobileOnly = styled.div`
  display: none;
  font-weight: 300px;
  width: 100%;

  @media (max-width: ${mobile}) {
    display: block;
    margin: 10px 0 22px;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: ${mobile}) {
    gap: 40px;
    margin-bottom: 12px;
  }

  svg {
    display: inline-block;
    height: 23px;
    width: 23px;
    fill: ${colors.secondaryButton};
  }

  a:hover {
    opacity: 0.9;
  }
`;


const Footer = () => {
  return (
    <Background>
      <Wrapper>
        <TextWrapper>
          <MobileHide>© 2024 Yedding, Inc.</MobileHide>
          <MobileHide>/</MobileHide>
          <a href={privacyPolicy} target="_blank">
            Privacy
          </a>
          <span>/</span>
          <a href={termsOfUse} target="_blank">
            Terms & Conditions
          </a>
          <span>/</span>
          <a href={`mailto:${companyEmail}`} target="_blank">
            Contact Us
          </a>
          <span>/</span>
          <a href={`sms:${companyPhone}`} target="_blank">
            Text Us
          </a>
        </TextWrapper>
        <MobileOnly>
          © 2024 Yedding, Inc.
        </MobileOnly>

        <SocialWrapper>
          <a target="_blank" href=" https://www.facebook.com/Yeddingllc">
            <LogoFacebookIcon />
          </a>
          <a target="_blank" href="https://www.instagram.com/yeddingllc/">
            <LogoInstagramIcon />
          </a>
          <a target="_blank" href="https://www.tiktok.com/@yeddingllc">
            <LogoTiktokIcon />
          </a>
          <a target="_blank" href="https://www.linkedin.com/company/yeddingllc/">
            <LogoLinkedinIcon />
          </a>
        </SocialWrapper>
      </Wrapper>
    </Background>
  );
};

export default Footer;
