import React from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

import { functions } from '../firebase'

const cancelReservedVenue = functions.httpsCallable('cancelReservedVenue')

export const companyEmail = 'yeddingllc@gmail.com'
export const companyPhone = '+18019234914'
export const privacyPolicy = '/privacy-policy.pdf'
export const termsOfUse = '/terms-of-use.pdf'
export const hostAgreement = '/host-agreement.pdf'
export const guestAgreement = '/guest-agreement.pdf'

const ANY_LOCATION = 'all'
export const isAnyLocation = location => (
  !location || location === ANY_LOCATION
)

// Venue's have an array of reserved dates. The dates are in reverse order
// because `dateRange` will always be in the future, and we want to exit
// the loop once the reserved dates are before the dateRange.
const _getIsAvailable = ({ start, end, badDates }) => {
  if (Array.isArray(badDates) && badDates.length) {
    for (let i=0; i<badDates.length; i++) {
      const date = badDates[i]
      // if the unavailable date is before the start date-range, exit the loop early
      if (start.isAfter(date)) {
        break
      }
      // if date is inside the range, return false
      if (end.isSameOrAfter(date)) {
        return false
      }
      // else, unavailable dates are still in the future, outside our `dateRange`
    }
  }
  return true
}
export const isAvailable = (dateRange, venue) => {
  const { startDate, endDate } = dateRange || {}
  const { bookedDates, excludedDates, disabled } = venue || {}

  const start = moment(startDate),
        end = moment(endDate)

  return (
    !disabled &&
    _getIsAvailable({ start, end, badDates: bookedDates }) && // check booked dates
    _getIsAvailable({ start, end, badDates: excludedDates })  // check blacklisted dates
  )
}

export const countDays = ({ startDate, endDate }) => {
  const start = moment(startDate)
  const end = moment(endDate)
  const days = end.diff(start, 'days') + 1
  return days
}

export const getBookingDetails = (venue) => {
  const isBooking = !!venue.booking
  const location =
    !venue.address
    ? ''
    : isBooking
    ? `${venue.address.street.trim()}, ${venue.address.city.trim()} ${venue.address.state.trim()} ${venue.address.zip.trim()}`
    : `${venue.address.city} ${venue.address.state}`;
  const startLabel = isBooking ? moment(venue.booking.start).format('MMM D') : ''
  const endLabel = isBooking ? moment(venue.booking.end).format('MMM D, YYYY') : ''
  const dateLabel = isBooking ? (venue.booking.start === venue.booking.end ? endLabel : `${startLabel} - ${endLabel}`) : ''
  const daysCount = isBooking ? countDays({ startDate: venue.booking.start, endDate: venue.booking.end }) : ''
  const bookingCost = isBooking ? `$${Number(venue.booking.amount / 100).toLocaleString()}` : ''
  const bookingCostLabel = isBooking ? `${bookingCost} (${daysCount} day${daysCount > 1 ? 's' : ''})${venue.booking.cancelledOn ? ' - cancelled' : ''}` : ''
  let bookingDateLabel = ''
  let isCancelled = false
  let onCancelClick = null
  if (isBooking) {
    const dateLabel = moment(venue.booking.bookedOn).format('MMM D, YYYY')
    if (typeof venue.booking.status === 'string' && dateLabel) {
      bookingDateLabel = `${venue.booking.status[0].toUpperCase()}${venue.booking.status.slice(1)} ${bookingCost} on ${dateLabel}`
    }
    if (venue.booking.cancelledOn) {
      // If event is cancelled, show feedback
      isCancelled = true
      const cancelDate = moment(venue.booking.cancelledOn).format('MMM D, YYYY')
      bookingDateLabel = `Cancelled on ${cancelDate}`
    } else {
      // If event is not already cancelled, and _can_ be cancelled - within grace period
      const cancellationPolicy = cancellationPolicies.find(({ name }) => name === venue.booking.cancellationPolicy)
      const cancelLimitDays = (cancellationPolicy || {}).days || 14
      const cancelLimit = moment(venue.booking.start).subtract(cancelLimitDays, 'days').valueOf()
      const withinGracePeriod = Date.now() < cancelLimit
      if (withinGracePeriod) {
        onCancelClick = async () => {
          await cancelReservedVenue({
            stripe_id: venue.booking.stripe_id,
            venue_id: venue.booking.venue_id,
            customer_id: venue.booking.customer_id,
            host_id: venue.booking.host_id,
          })
          window.location.reload()
        }
      }
    }
  }
  // If the customer is looking at the page, the name & email should be the host.
  // If the host is viewing the page, the name & email should be the customer.
  const bookingName = venue.booking ? venue.booking.customerName : venue.host.name
  const bookingEmail = venue.booking ? venue.booking.customerEmail : venue.host.email
  return {
    isBooking,
    isCancelled,
    location,
    startLabel,
    endLabel,
    dateLabel,
    daysCount,
    bookingCost,
    bookingCostLabel,
    bookingDateLabel,
    bookingName,
    bookingEmail,
    onCancelClick,
  }
}

export const getHostedBookingsByVenue = user => (
  user && user.hostedBookings
    ? Object.values(user.hostedBookings).reduce((all, booking) => ({
      ...all,
      [booking.venue_id]: [
        ...(all[booking.venue_id] || []),
        booking,
      ]
    }), {})
    : {}
)

export const makeSearchUrl = ({
  location: loc,
  start,
  end,
  guests,
  page,
  id, // venue id selected for detail-view
}) => {
  loc = loc || ANY_LOCATION
  start = start && start !== 'none' ? moment(start).format('YYYY-MM-DD') : 'none'
  end = end && end !== 'none' ? moment(end).format('YYYY-MM-DD') : 'none'
  guests = Number(guests) || 0
  page = Number(page) || 1

  const query = new URLSearchParams({
    loc,
    start,
    end,
    guests,
    page,
    ...( id ? { id } : {})
  })
  return `/search/?${query.toString()}`
}

export const makeFavoritesUrl = ({
  page,
  id,   // venue id selected for detail-view
}) => {
  const query = new URLSearchParams({
    page: Number(page) || 1,
    ...( id ? { id } : {})
  })
  return `/favorites/?${query.toString()}`
}

export const makeReservationsUrl = ({
  page,
  id,   // venue id selected for detail-view
}) => {
  const query = new URLSearchParams({
    page: Number(page) || 1,
    ...( id ? { id } : {})
  })
  return `/reservations/?${query.toString()}`
}

export const makeHostReservationsUrl = ({
  page,
  id,   // venue id selected for detail-view
}) => {
  const query = new URLSearchParams({
    page: Number(page) || 1,
    ...( id ? { id } : {})
  })
  return `/host/reservations/?${query.toString()}`
}

export const makeHostedUrl = ({
  page,
  id,   // venue id selected for detail-view
}) => {
  const query = new URLSearchParams({
    page: Number(page) || 1,
    ...( id ? { id } : {})
  })
  return `/host/venues/?${query.toString()}`
}

// NOTE: used by all list pages: search, favorites, reservations, ...
export const useSearchParams = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  // make the names consistent with makeSearchUrl
  return {
    location: query.get('loc'),
    guests: Number(query.get('guests')),
    start: query.get('start') || 'none',
    end: query.get('end') || 'none',
    page: Math.max(Number(query.get('page')), 1),
    id: query.get('id'),
  }
}

export const getIsAdmin = user => (
  user && user.email === 'admin@yedding.com'
)

export const cancellationPolicies = [{
  name: 'flexible',
  timeline: '48 hours',
  days: 2, // for calculation
  // refund: 'full refund',
}, {
  name: 'moderate',
  timeline: '14 days',
  days: 14,
  // refund: '50%',
}, {
  name: 'strict',
  timeline: '21 days',
  days: 21,
  // refund: '50%',
}]

export const amenities = [
  'On-site Bathrooms',
  'Changing Rooms',
  'Dance Floor',
  'WiFi',
  'Tables',
  'Lights',
  'Kitchen',
  'Chairs',
  'Brides Room',
  'On-site Parking',
  'Pool',
  'Running Water',
  'Linens',
  'Staging Room',
]

export const venueSettings = [
  "outdoor",
  "indoor",
  "indoor/outdoor",
]

export const venueTypes = [
  "barn",
  "bed & breakfast",
  "commercial",
  "home",
  "hotel",
  "park",
  "unique space",
]

export const states = [
  "AL",
  "AK",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WI",
  "WV",
  "WY"
]
