import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

import { mobile, tablet, colors } from '../../../styles'

const loggedOutMobile = '960px'
const hostButtonCutoff = '1000px'

const buttonStyle = css`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const textButtonStyle = css`
  ${buttonStyle}
  font-size: 14px;
  font-weight: 500;

  span:last-of-type {
    display: inline-block;
    border-bottom: 1px solid transparent;
  }
  &:hover, &:focus {
    span:last-of-type {
      border-bottom-color: ${colors.defaultText};
    }
  }
`

///////////////////////////////
//     Header Navigation     //
///////////////////////////////

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`

export const MenuButton = styled.button`
  ${buttonStyle}
  padding: 0;
  margin: 0;

  svg {
    height: 15px;
    fill: ${colors.defaultText};
    display: block;
    margin-top: -2px;
  }
`

export const BecomeHost = styled.button`
  ${textButtonStyle}
  margin-right: 32px;

  @media (max-width: ${hostButtonCutoff}) {
    display: none;
  }

  svg {
    display: inline-block;
    margin-top: -3px;
    margin-right: 4px;
    height: 18px;
    width: 18px;
  }
`

export const AuthButton = styled.button`
  ${textButtonStyle}
  padding: 0;

  @media (max-width: ${loggedOutMobile}) {
    display: none;
  }
`

export const AuthIconButton = styled.button`
  ${buttonStyle}
  display: none;

  @media (max-width: ${loggedOutMobile}) {
    display: block;
  }

  svg {
    height: 20px;
    fill: ${colors.defaultText};
    display: block;
    margin-top: -3px;
  }
`

export const Divider = styled.div`
  margin: 0 16px;
  height: 13px;
  width: 1px;
  background-color: ${colors.lightBorder};

  @media (max-width: ${loggedOutMobile}) {
    display: none;
  }
`

//////////////////////////
//      Modal Links     //
//////////////////////////

export const Modal = styled.div`
  position: absolute;
  right: -10px;
  width: 329px;
  box-sizing: border-box;
  padding: 24px;
  z-index: 20;
  background-color: ${colors.whiteBackground};
  border: 1px solid ${colors.mediumBorder};
  border-radius: 6px;
  box-shadow: rgba(0,0,0,0.1) 2px 2px 5px;

  ${({ omitSearch }) => omitSearch ? css`
    top: -17px;
  ` : css`
    top: -22px;

    @media (max-width: ${tablet}) {
      top: -17px;
    }

    @media (max-width: ${mobile}) {
      top: -22px;
    }
  `}
`

export const ModalHeader = styled.div`
  display: flex;

  & > div:first-child {
    flex-grow: 1;
    flex-shrink: 0;
  }

  & > div:last-child {
    flex-grow: 0;
    flex-shrink: 0;

    & > button {
      border: none;
      background-color: transparent;
      padding: 0;
      margin: 0;

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
`

export const ModalName = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 8px;
`

export const ModalEmail = styled.div`
  font-size: 12px;
  line-height: 1.2;
  color: ${colors.fadedText};
`

export const ModalDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.mediumBorder};
  margin: 32px 0;
`

export const ModalLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  a, button {
    ${buttonStyle}
    font-weight: 500;
    font-size: 16px;
    color: ${colors.defaultText};
    text-align: left;
    margin: 0;
    padding: 0;

    &:hover, &:focus {
      span:last-child {
        text-decoration: underline;
      }
    }

    svg {
      display: inline-block;
      margin-top: -2px;
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }
`

export const ModalLink = styled(Link)``

export const ModalHostButton = styled.button`
  display: none;

  @media (max-width: ${hostButtonCutoff}) {
    display: block;
  }
`

export const ModalLogoutButton = styled(Link)`
  border: 1px solid ${colors.primaryButton};
  color: ${colors.primaryButton};
  text-align: center;
  display: block;
  padding: 15.5px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 4px;

  &:hover, &:focus {
    outline: none;
    color: ${colors.whiteBackground};
    background-color: ${colors.primaryButton};
  }
`

export const ModalUnderlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  z-index: 10;
`
