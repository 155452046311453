import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { signOutUser } from '../firebase/user'

const PageLogout = () => {
  const history = useHistory()

  useLayoutEffect(() => {
    signOutUser(
      () => {
        history.push({
          pathname: '/'
        })
      },
      (error) => {
        console.error(error)
      }
    )
  }, [])

  return null
}

export default PageLogout
