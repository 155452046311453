import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import ImageCarousel from '../../Shared/ImageCarousel.jsx'
import {
  ImagesIcon,
  tablet,
  mobile,
} from '../../styles'


const ImageContent = styled.div`
  position: relative;
  margin: 32px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  @media (max-width: ${tablet}) {
    margin: 24px 0;
    gap: 7px;
  }

  @media (max-width: ${mobile}) {
    margin: 15px 0 18px;
  }

  & > div {
    flex: 0 0 30%;

    button {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;

      &:focus:not(:focus-visible) {
        outline: none;
      }
    }
  }

  & > div:first-child {
    flex-grow: 1;

    img {
      height: 322px;

      @media (max-width: ${tablet}) {
        height: 257px;
      }

      @media (max-width: ${mobile}) {
        height: 180px;
      }
    }
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    img {
      height: 157px;

      @media (max-width: ${tablet}) {
        height: 125px;
      }

      @media (max-width: ${mobile}) {
        height: 87px;
      }
    }
  }

  img {
    border-radius: 4px;
    width: 100%;
    object-fit: cover;
  }
`

const MorePhotos = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  padding: 9px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(5px);

  svg {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-top: -2px;
  }
`

const CarouselWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.75);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;

  @media (min-width: 700px) {
    padding: 0 10vw;
  }

  @media (min-width: 1200px) {
    padding: 0 20vw;
  }

  @media (min-width: 2000px) {
    padding: 0 25vw;
  }
`


const Photos = ({ photos }) => {
  const [showImage, setShowImage] = useState(-1)
  const ref = useRef(null)

  if (!photos.length) {
    return null
  }

  const [
    photo1,
    photo2,
    photo3,
    ...more
  ] = photos
  const hasMore = !!more.length

  return (
    <>
      <ImageContent>
        <div>
        {!!photo1 && (
          <button onClick={() => setShowImage(0)}>
            <img src={photo1.thumbnail} />
          </button>
        )}
        </div>
        <div>
        {!!photo2 && (
          <>
            <button onClick={() => setShowImage(1)}>
              <img src={photo2.thumbnail} />
            </button>
            {!!photo3 && (
              <button onClick={() => setShowImage(2)}>
                <img src={photo3.thumbnail} />
              </button>
            )}
            {hasMore && (
              <MorePhotos>
                <ImagesIcon />
                + {more.length}
              </MorePhotos>
            )}
          </>
        )}
        </div>
      </ImageContent>
      {showImage >= 0 && (
        <CarouselWrapper
          ref={ref}
          onClick={e => {
            // close if background element clicked directly (and not bubbled)
            if (e.target === ref.current) {
              setShowImage(-1)
            }
          }}
        >
          <ImageCarousel
            images={photos.map(photo => photo.url)}
            defaultIndex={showImage}
          />
        </CarouselWrapper>
      )}
    </>
  )
}

export default Photos