import React from 'react'

import RequireUser from '../components/RequireUser'
import HostStripeRedirect from '../components/HostStripeRedirect'

function PageHostPayout() {
  return (
    <RequireUser>
      <HostStripeRedirect />
    </RequireUser>
  )
}

export default PageHostPayout
