/* eslint-disable no-shadow */
import { functions } from '.';
import moment from 'moment'

const checkOnBoard = functions.httpsCallable('checkOnBoard');
const retrieveBalance = functions.httpsCallable('retrieveBalance');
const createCheckoutLink = functions.httpsCallable('createCheckoutLink')

export const startSetDashBoard = (stripeAccountId, setDetails, setAvailable, setPending, setFetching) => {
  checkOnBoard({ stripeAccountId })
    .then(({ data }) => {
      setDetails(data.details_submitted);
      retrieveBalance({ stripeAccountId })
        .then(({ data }) => {
          setAvailable((data.available[0].amount / 100).toFixed(2));
          setPending((data.pending[0].amount / 100).toFixed(2));
          setTimeout(() => {
            setFetching(false);
          }, 100);
        })
        .catch((error) => {
          console.log(
            `There was an error when calling the Cloud Function retrieveBalance:\n\nError Code: ${error.code}\nError Message:${error.message}\nError Details:${error.details}`
          );
          setFetching(false);
        });
    })
    .catch((error) =>
      console.log(
        `There was an error when calling the Cloud Function checkOnBoard:\n\nError Code: ${error.code}\nError Message:${error.message}\nError Details:${error.details}`
      )
    );
};

export const makeCheckoutLink = async ({ user, venue, days, start, end }) => {
  const success_url = `${window.location.origin}/confirmation?venue=${venue.id}&start=${start}&end=${end}`
  const cancel_url = window.location.href
  const startDate = moment(start).format('M/D/YY')
  const endDate = moment(end).format('M/D/YY')
  const dateRange = startDate === endDate ? startDate : `${startDate} - ${endDate}`
  try {
    const { data: url } = await createCheckoutLink({
      isTest: window.location.hostname === 'localhost',
      user: {
        id: user.id,
        email: user.email,
      },
      venue: {
        id: venue.id,
        name: venue.name.trim(),
        image: venue.photos.map(photo => photo.thumbnail).slice(0,1),
        dayPrice: venue.dayPrice
      },
      days: Number(days),
      dateRange,
      start,
      end,
      success_url,
      cancel_url,
    })
    return { url }
  } catch (e) {
    return {
      error: true,
      unauthenticated:
        e.message === 'The function must be called while authenticated.'
    }
  }
}
