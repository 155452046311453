import React from 'react'
import { useDispatch } from 'react-redux'

import { setShowDatePicker } from '../../../../../store/search'
import { prettyDate } from './helpers'

import {
  DateSeperator,
  Label,
  DateText,
  DateTrigger,
} from '../styles'

export default function DateLabel({
  dateRange,
}) {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(setShowDatePicker())
  }

  return (
    <DateTrigger onClick={onClick}>
      <div>
        <Label>Start</Label>
        <DateText>{prettyDate(dateRange.startDate)}</DateText>
      </div>
      <DateSeperator />
      <div>
        <Label>End</Label>
        <DateText>{prettyDate(dateRange.endDate)}</DateText>
      </div>
    </DateTrigger>
  )
}