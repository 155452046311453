import React, { useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { startSearchResults } from '../firebase/venues'
import { useSearchParams } from '../components/helpers'
import { getIsFetching, setIsFetching, setSearchResults } from '../store/search'

import SearchVenues from '../components/SearchVenues'
import Loading from '../components/Loading'

const PageSearchVenues = () => {
  const {
    location,
    guests: numGuests,
    start: startDate,
    end: endDate,
  } = useSearchParams()
  const dispatch = useDispatch()
  const isFetching = useSelector(getIsFetching)

  useLayoutEffect(() => {
    startSearchResults({
      setFetching(value) {
        dispatch(setIsFetching(value))
      },
      setSearchResults(results) {
        dispatch(setSearchResults(results))
      },
      location,
      numGuests,
      startDate,
      endDate,
    })
  }, [
    location,
    numGuests,
    startDate,
    endDate,
  ])

  if (isFetching) {
    return <Loading />
  }

  return <SearchVenues />
};

export default PageSearchVenues
