import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import moment from 'moment'

import Header from '../Shared/NewHeader'
import Footer from '../Shared/NewFooter'

import {
  mobile,
  tablet,
  colors,
  headingStyles,
  capitalize,
  PageWrapper,
  ContentWrapper,
  Content,
  PeopleIcon,
  IndoorOutdoorIcon,
  VenueIcon,
  ConfettiIcon,
  LocationIcon,
  CalendarIcon,
} from '../styles'

const Media = styled.div`
  text-align: center;
`

const Title = styled.div`
  color: ${colors.defaultText};
  text-align: center;
  /* font-family: Bely; */
  font-size: 44px;
  ${headingStyles}
  font-weight: 700;
  line-height: 1;
  margin-top: 40px;

  @media (max-width: ${mobile}) {
    font-size: 32px;
  }
`

const Description = styled.div`
  color: ${colors.semiDarkText};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 16px;
`

const Venue = styled.div`
  width: 474px;
  border-radius: 6px;
  border: 1px solid ${colors.lightBorder};
  background: ${colors.whiteBackground};
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.06);

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 40px auto 0;
  padding: 16px;
  gap: 16px;

  font-size: 12px;
  font-weight: 400;
  color: ${colors.defaultText};

  @media (max-width: ${mobile}) {
    flex-direction: column-reverse;
    width: auto;
  }

  & > img {
    flex-shrink: 0;
    flex-grow: 0;
    height: 99px;
    width: 99px;
    object-fit: cover;
    border-radius: 6px;

    @media (max-width: ${mobile}) {
      flex-grow: 1;
      width: 100%;
      height: 200px;
    }
  }

  & > div {
    flex-shrink: 1;
    flex-grow: 1;
  }

  svg {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 1px;
    margin-top: -2px;
  }
`

const VenueTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const VenueLocation = styled.div`
  margin-top: 4px;

  svg {
    fill: ${colors.primaryIcon};
  }
`

const ReservationDate = styled.div`
  margin-top: 7px;

  svg {
    fill: ${colors.primaryIcon};
  }
`


const Attributes = styled.div`
  display: flex;
  margin-top: 7px;
  flex-wrap: wrap;
  overflow: hidden;
  height: 21px;

  @media (max-width: ${tablet}) {
    font-size: 12px;
    height: 18px;
  }

  div {
    flex: 0 0 auto;
    text-align: left;
    padding-right: 16px;
  }
`

// const ReceiptInfo = styled.div`
//   margin-top: 7px;
//   background-color: ${colors.lightBackground};
//   padding: 4px 6px;
//   border-radius: 2px;
//   display: inline-block;
// `

const HomeButton = styled.a`
  margin: 24px auto 0;
  min-width: 150px;
  padding: 12.5px 16px;
  text-align: center;
  border-radius: 4px;
  border: 1.4px solid ${colors.primaryButton};
  color: ${colors.primaryButton};
  font-size: 14px;
  font-weight: 500;

  &:hover {
    background-color: ${colors.primaryBackground};
    color: ${colors.primaryButton};
  }
`


function Confirmation({
  venue,
  startDate,
  endDate,
}) {
  const title = venue?.name || ''
  const image =
    Array.isArray(venue?.photos) && venue?.photos.length
    ? venue?.photos[0].thumbnail
    : null
  const location = `${venue.address.street.trim()}, ${venue.address.city.trim()} ${venue.address.state.trim()} ${venue.address.zip.trim()}`;
  const startLabel = moment(startDate).format('MMM D')
  const endLabel = moment(endDate).format('MMM D, YYYY')

  return (
    <PageWrapper>
      <Header />
      <ContentWrapper>
        <Content>
          <Media>
            <ConfettiIcon />
          </Media>
          <Title>
            Congratulations!
          </Title>
          <Description>
            Your order is confirmed and on its way to making your vision a reality.
          </Description>
          <Venue>
            {image && <img src={image} alt={title} />}
            <div>
              <VenueTitle>{title}</VenueTitle>
              <VenueLocation>
                <LocationIcon />
                {location}
              </VenueLocation>
              <ReservationDate>
                <CalendarIcon />
                {startDate === endDate ? endLabel : (
                  <>{startLabel} &ndash; {endLabel}</>
                )}
              </ReservationDate>
              <Attributes>
                <div><PeopleIcon />{venue.maxGuests} Guests</div>
                <div><IndoorOutdoorIcon />{capitalize(venue.setting)}</div>
                <div><VenueIcon />{capitalize(venue.venueType)}</div>
              </Attributes>
              {/* {!!confirmationId && (
                <ReceiptInfo>
                  {confirmationId}
                </ReceiptInfo>
              )} */}
            </div>
          </Venue>
          <HomeButton href="/reservations/">
            View Reservations
          </HomeButton>
        </Content>
      </ContentWrapper>
      <Footer />
    </PageWrapper>
  )
}

export default Confirmation
