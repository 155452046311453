import React from 'react'
import styled, { css } from 'styled-components'

import {
  notMobile,
  tablet,
  mobile,
  colors,
  capitalize,
  PeopleIcon,
  IndoorOutdoorIcon,
  VenueIcon,
  LocationIcon,
  EventsIcon,
} from '../../styles'
const customMobile = '1080px'

const Venue = styled.button`
  width: 474px;
  border-radius: 6px;
  border: 1px solid ${colors.lightBorder};
  background-color: ${colors.whiteBackground};
  text-align: left;
  box-sizing: border-box;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    border-color: ${colors.defaultBorder};
  }

  ${({ selected }) => selected && css`
    @media (min-width: ${notMobile}) {
      background-color: ${colors.primaryBackground};
    }
  `}

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 16px;
  padding: 16px;
  gap: 16px;

  font-size: 12px;
  font-weight: 400;
  color: ${colors.defaultText};

  @media (max-width: ${tablet}) {
    width: 424px;
  }

  @media (max-width: ${customMobile}) {
    flex-direction: column-reverse;
    width: 310px;
  }

  @media (max-width: ${mobile}) {
    width: 100%;
  }

  & > img {
    flex-shrink: 0;
    flex-grow: 0;
    height: 99px;
    width: 99px;
    object-fit: cover;
    border-radius: 6px;

    @media (max-width: ${customMobile}) {
      flex-grow: 1;
      width: 100%;
      height: 160px;
    }
  }

  & > div {
    flex-shrink: 1;
    flex-grow: 1;
  }

  svg {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 1px;
    margin-top: -2px;
  }
`

const VenueTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
`

const VenueLocation = styled.div`
  margin-top: 4px;

  svg {
    fill: ${colors.primaryIcon};
  }
`

const ReservationDate = styled.div`
  margin-top: 7px;

  svg {
    fill: ${colors.primaryIcon};
  }
`


const Attributes = styled.div`
  display: flex;
  margin-top: 7px;
  flex-wrap: wrap;
  overflow: hidden;
  height: 21px;

  @media (max-width: ${tablet}) {
    font-size: 12px;
    height: 18px;
  }

  div {
    flex: 0 0 auto;
    text-align: left;
    padding-right: 16px;
  }
`

function VenueSmall({
  venue,
  selected,
  setSelectedVenue,
}) {
  const title = venue?.name || ''
  const image =
    Array.isArray(venue?.photos) && venue?.photos.length
    ? venue?.photos[0].thumbnail
    : null
  const location = `${venue.address.street.trim()}, ${venue.address.city.trim()} ${venue.address.state.trim()} ${venue.address.zip.trim()}`;
  const eventsCount = venue.totalBookings || 0
  const isSelected = !!selected && selected.id === venue.id

  return (
    <Venue
      onClick={() => {
        setSelectedVenue(venue.id)
      }}
      selected={isSelected}
    >
      {image && <img src={image} alt={title} />}
      <div>
        <VenueTitle>{title}</VenueTitle>
        <VenueLocation>
          <LocationIcon />
          {location}
        </VenueLocation>
        <ReservationDate>
          <EventsIcon />
          {eventsCount} total event{eventsCount === 1 ? '' : 's'}
        </ReservationDate>
        <Attributes>
          <div><PeopleIcon />{venue.maxGuests} Guests</div>
          <div><IndoorOutdoorIcon />{capitalize(venue.setting)}</div>
          <div><VenueIcon />{capitalize(venue.venueType)}</div>
        </Attributes>
      </div>
    </Venue>
  )
}

export default VenueSmall
