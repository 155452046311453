import React from 'react'
import { useDispatch } from 'react-redux'

import { setShowSignIn, setShowSignUp } from '../../../../store/user'
import { ProfileIcon } from '../../../styles'
import {
  MenuWrapper,
  AuthIconButton,
  AuthButton,
  Divider,
} from './styles'

export default function LoggedOutMenu() {
  const dispatch = useDispatch()

  return (
    <MenuWrapper>
      <AuthIconButton
        onClick={() => {
          dispatch(setShowSignIn())
        }}
      >
        <ProfileIcon />
      </AuthIconButton>
      <AuthButton
        onClick={() => {
          dispatch(setShowSignIn())
        }}
      >
        <span>Sign In</span>
      </AuthButton>
      <Divider />
      <AuthButton
        onClick={() => {
          dispatch(setShowSignUp())
        }}
      >
        <span>Sign Up</span>
      </AuthButton>
    </MenuWrapper>
  )
}
