import React from 'react';
import styled from 'styled-components';

import {
  tablet,
  capitalize,
  PeopleIcon,
  IndoorOutdoorIcon,
  VenueIcon,
} from '../../styles'


const CardAttributes = styled.div`
  display: flex;
  font-size: 14px;
  margin: 14px 0;
  flex-wrap: wrap;
  overflow: hidden;
  height: 21px;

  &:last-child {
    font-size: 12px;
    margin-bottom: 0;
  }

  @media (max-width: ${tablet}) {
    font-size: 12px;
    height: 18px;
    margin-top: 12px;
  }

  div {
    flex: 0 0 auto;
    text-align: left;
    padding-right: 16px;
  }

  svg {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 2px; // old: 6
    margin-top: -2px;

    @media (max-width: ${tablet}) {
      width: 14px;
      height: 14px;
      margin-top: -3px;
    }
  }
`


const Attributes = ({ venue }) => (
  <CardAttributes>
    <div><PeopleIcon />{venue.maxGuests} Guests</div>
    <div><IndoorOutdoorIcon />{capitalize(venue.setting)}</div>
    <div><VenueIcon />{capitalize(venue.venueType)}</div>
  </CardAttributes>
)

export default Attributes
