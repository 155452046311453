import React from 'react'
import styled from 'styled-components'

import { colors } from '../styles'

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border: ${(props) => props.size / 8}px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

// eslint-disable-next-line react/prop-types
const Loader = ({ size = 60 }) => (
  <StyledSpinner role="img" aria-label="Loading" color={colors.primaryIcon} size={size}>
    <div />
    <div />
    <div />
    <div />
  </StyledSpinner>
)

const LoadWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`


const Loading = () => {
  return (
    <LoadWrapper>
      <Loader />
    </LoadWrapper>
  )
}

export default Loading
