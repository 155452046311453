import { createSlice, createSelector } from '@reduxjs/toolkit'


////////////////////////////
//   Reducers & Actions   //
////////////////////////////

const initialState = {
  isFetching: true,
  venues: [],
  // selected: null, // ID of selected venue
  pageSize: 0,
  totalPages: 0,
  hasResults: false,
  showDatePicker: false,
  bookVenue: null,
}

const search = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload === true
    },
    setSearchResults(state, action) {
      if (action.payload && Array.isArray(action.payload.venues)) {
        state.venues = action.payload.venues
        state.pageSize = action.payload.pageSize
        state.totalPages = action.payload.totalPages
        state.hasResults = action.payload.hasResults
      }
    },
    // setSelectedVenue(state, action) {
    //   if (typeof action.payload === 'object' && action.payload && action.payload.id) {
    //     state.selected = action.payload.id
    //   } else if (typeof action.payload === 'string' && action.payload) {
    //     state.selected = action.payload
    //   } else {
    //     state.selected = null
    //   }
    // },
    setShowDatePicker(state, action) {
      state.showDatePicker = true
    },
    setConfirmVenue(state, action) {
      state.bookVenue = action.payload
      // NOTE: old way - history.push(`/bookvenue/${venue.id}`)
    },
    closeSearch(state) {
      state.showDatePicker = false
      state.bookVenue = null
    },
  },
})

export default search.reducer

export const {
  setIsFetching,
  setSearchResults,
  // setSelectedVenue,
  setShowDatePicker,
  setConfirmVenue,
  closeSearch,
} = search.actions


////////////////////////////
//   Selector Functions   //
////////////////////////////

const selectSelf = state => state.search

export const getIsFetching = createSelector(selectSelf, state => state.isFetching)
export const getSearchResults = createSelector(selectSelf, state => state)
// export const getSelectedVenue = createSelector(selectSelf, state => {
//   return !state.selected ? null : state.venues.find(venue => (
//     venue.id === state.selected
//   ))
// })
export const getShowDatePicker = createSelector(selectSelf, state => state.showDatePicker)
export const getConfirmVenue = createSelector(selectSelf, state => state.bookVenue)
