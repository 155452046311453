import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components'

import Footer from '../Shared/NewFooter';
import Header from '../Shared/NewHeader';
import UsersTable from './UsersTable.jsx';
import { adminGetUsers, adminGetVenues, setUserVerified, setVenueVerified } from '../../firebase/admin';
import { useAppContext } from '../../context/state';
import VenuesTable from './VenuesTable.jsx';
import {
  PageWrapper,
  ContentWrapper,
  Content,
  tablet,
  mobile,
} from '../styles'

const AdminWrapper = styled(ContentWrapper)`
  padding-top: 88px;

  @media (max-width: ${tablet}) {
    padding-top: 72px;
  }

  @media (max-width: ${mobile}) {
    padding-top: 44px;
  }
`

function Admin() {
  const [usersList, setUsersList] = useState([]);
  const [venuesList, setVenuesList] = useState([]);
  const { userData } = useAppContext();
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItemsVenues, setCurrentItemsVenues] = useState([]);
  const [pageCountVenues, setPageCountVenues] = useState(0);
  const [itemOffsetVenues, setItemOffsetVenues] = useState(0);
  const reloadData = () => {
    adminGetUsers((users) => setUsersList(users));
    adminGetUsers((users) => setCurrentItems(users?.slice(0, 10)));
    adminGetUsers((users) => setPageCount(Math.ceil(users.length / 10)));

    adminGetVenues((venues) => setVenuesList(venues));
    adminGetVenues((venues) => setCurrentItemsVenues(venues?.slice(0, 10)));
    adminGetVenues((venues) => setPageCountVenues(Math.ceil(venues.length / 10)));
  };
  useEffect(() => {
    reloadData();
  }, [userData]);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(usersList?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(usersList.length / 10));
  }, [itemOffset, 10]);

  useEffect(() => {
    const endOffset = itemOffsetVenues + 10;
    setCurrentItemsVenues(venuesList?.slice(itemOffsetVenues, endOffset));
    setPageCountVenues(Math.ceil(venuesList.length / 10));
  }, [itemOffsetVenues, 10]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % usersList.length;
    setItemOffset(newOffset);
  };
  const handlePageClickVenues = (event) => {
    const newOffset = (event.selected * 10) % venuesList.length;
    setItemOffsetVenues(newOffset);
  };

  return (
    <PageWrapper>
      <Header omitSearch />
      <AdminWrapper>
        <Content>
        <UsersTable users={currentItems} setUserVerified={setUserVerified} reloadData={reloadData}></UsersTable>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageCount={pageCount}
            pageRangeDisplayed={5}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        <br />
        <VenuesTable venues={currentItemsVenues} setVenueVerified={setVenueVerified} reloadData={reloadData}></VenuesTable>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClickVenues}
            pageCount={pageCountVenues}
            pageRangeDisplayed={5}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
        </Content>
      </AdminWrapper>
      <Footer />
    </PageWrapper>
  );
}

export default Admin;
