import React from 'react'
import styled from 'styled-components'

import NoImage from '../../Shared/assets/no-picture-available-icon-1.jpeg'

import {
  tablet,
  mobile,
} from '../../styles'


const CardThumbnailWrapper = styled.div`
  flex: 0 0 auto;
`

const CardThumbnail = styled.img`
  height: 149px;
  width: 149px;
  border-radius: 4px;
  object-fit: cover;

  @media (max-width: ${tablet}) {
    height: 100%;
    width: 100px;
  }

  @media (max-width: ${mobile}) {
    height: auto;
    max-height: 320px;
    width: 100%;
    margin-top: 14px;
  }
`


const Thumbnail = ({ photos }) => (
  <CardThumbnailWrapper>
    <CardThumbnail
      src={Array.isArray(photos) && photos[0] ? photos[0].thumbnail : NoImage}
    />
  </CardThumbnailWrapper>
)

export default Thumbnail
