import React from 'react';
import PropTypes from 'prop-types';

const UsersTable = (props) => {
  const { users, setUserVerified, reloadData } = props;
  if (!users || !users.length) return <>... Loading</>;
  return (
    <>
      <h3>Users</h3>
      <table className="table table-hover table-stripped">
        <thead>
          <tr>
            <td>Id</td>
            <td>Email</td>
            <td>Name</td>
            <td>Type</td>
            <td>Gov. Id</td>
            <td>Gov. Id Pic</td>
            <td>Contract</td>
            <td>Bank Acc.</td>
            <td>Verified</td>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => {
            return (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{`${user.lastName}, ${user.firstName}`}</td>
                <td>{user.isHost ? 'Host' : 'User'}</td>
                <td>{user.govermentId}</td>
                <td>
                  {user.govermentIdPicture ? (
                    <a href={user.govermentIdPicture.url} target="_blank" rel="noreferrer">
                      View Pic
                    </a>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {user.contractPicture ? (
                    <a href={user.contractPicture.url} target="_blank" rel="noreferrer">
                      View Contract
                    </a>
                  ) : (
                    <></>
                  )}
                </td>
                <td>
                  {user.bankName && user.bankRoutingNumber && user.bankAccountName && (
                    <ul>
                      <li>{`Bank Name: ${user.bankName}`}</li>
                      <li>{`Bank Routing Number: ${user.bankRoutingNumber}`}</li>
                      <li>{`Bank Account Number: ${user.bankAccountNumber}`}</li>
                      <li>{`Bank Name: ${user.bankAccountName}`}</li>
                    </ul>
                  )}
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.verified}
                    onChange={() => {
                      setUserVerified(user.id, !user.verified, () => {
                        reloadData();
                      });
                    }}
                  ></input>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

UsersTable.propTypes = {
  users: PropTypes.array,
  setUserVerified: PropTypes.func,
  reloadData: PropTypes.func
};

export default UsersTable;
