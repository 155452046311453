import React from 'react'

import useHostPayout from '../../hooks/useHostPayout'
import Loading from '../Loading'

const HostStripeRedirect = () => {
  useHostPayout()

  return <Loading />
}

export default HostStripeRedirect
