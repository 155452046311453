import { createContext, useContext, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { getUser, setUser } from '../store/user'
import { auth } from '../firebase';
import { getUserData } from '../firebase/user';

export const AppContext = createContext();

export function AppWrapper({ children }) {
  // declare the variables we want available throughout
  // the whole app here, using useState hook

  // IDEA: Keep the wrapper, but just continuously move state out into redux.

  // support legacy pages
  const dispatch = useDispatch()
  const userData = useSelector(getUser)
  const setUserData = useCallback(value => {
    dispatch(setUser(value))
  }, [dispatch])

  const [fetching, setFetching] = useState(true); // TODO: might be dead
  const [selectedVenue, setSelectedVenue] = useState({});
  const [searchParams, setSearchParams] = useState({});
  const [savedVenues, setSavedVenues] = useState([]);
  const [reservedVenues, setReservedVenues] = useState([]);
  const [hostedVenues, setHostedVenues] = useState([]);
  const [editVenue, setEditVenue] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [ddMenuAlerts, setddMenuAlerts] = useState({
    Messages: 0,
    Reservations: 0,
    Saved: 0
  });
  const [details, setDetails] = useState(null);
  const [available, setAvailable] = useState(null);
  const [pending, setPending] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // if user exists
      if (user) {
        // set user data
        getUserData(setUserData)
      } else {
        setUserData(null)
      }
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        editVenue,
        userData,
        fetching,
        selectedVenue,
        searchParams,
        savedVenues,
        reservedVenues,
        hostedVenues,
        ddMenuAlerts,
        details,
        available,
        pending,
        setEditVenue,
        setUserData,
        setFetching,
        setSelectedVenue,
        setSearchParams,
        setSavedVenues,
        setReservedVenues,
        setHostedVenues,
        setddMenuAlerts,
        setDetails,
        setAvailable,
        setPending,
        showLoginModal,
        setShowLoginModal,
        showSignupModal,
        setShowSignupModal
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}

AppWrapper.propTypes = {
  children: PropTypes.object
};
