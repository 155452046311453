import React from 'react'

import RequireUser from '../components/RequireUser'
import Profile from '../components/Profile'

function PageProfile() {
  return (
    <RequireUser>
      <Profile />
    </RequireUser>
  )
}

export default PageProfile
