import React from 'react'
import styled, { css } from 'styled-components'

import {
  notMobile,
  mobile,
  tablet,
  capitalize,
  colors,
  headingStyles,
  LocationIcon,
  PeopleIcon,
  IndoorOutdoorIcon,
  VenueIcon,
  HostIcon,
  CloseIcon,
  CalendarIcon,
  CreditCardIcon,
} from '../../styles'
import { getBookingDetails } from '../../helpers'
import useBookVenue from '../../../hooks/useBookVenue'
import Photos from './Photos'
import AmenityIcon from './AmenityIcon'
import CancelBooking from './CancelBooking'
import CancellationPolicies from '../../AddEditVenue/AddEditHelper/CancellationPolicies.jsx'

const Background = styled.div`
  color: ${colors.defaultText};
`

const HeaderContent = styled.div`
  display: flex;
  align-items: stretch;
  gap: 16px;
  min-height: 115px;

  @media (max-width: ${tablet}) {
    min-height: 100px;
  }

  @media (max-width: ${mobile}) {
    min-height: 0;
  }
`

const TitleContent = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  ${headingStyles}
  font-size: 32px;
  line-height: 1.2;

  @media (max-width: ${tablet}) {
    font-size: 24px;
  }

  @media (max-width: ${mobile}) {
    font-size: 22px;
  }
`

const Location = styled.div`
  font-size: 14px;
  line-height: 1.2;
  margin-top: 12px;
  flex-grow: 1; // position attributes to the bottom

  @media (max-width: ${tablet}) {
    font-size: 12px;
  }

  svg {
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-right: 2px;
    margin-top: -2px;
    fill: ${colors.primaryIcon};
  }
`

const Attributes = styled.div`
  display: flex;
  font-size: 14px;
  margin-top: 15px;

  @media (max-width: ${tablet}) {
    font-size: 12px;
    flex-wrap: wrap;
    height: 18px;
    overflow: hidden;
  }

  div {
    flex: 0 0 auto;
    text-align: left;
  }
  div + div {
    padding-left: 22px;

    @media (max-width: ${tablet}) {
      padding-left: 16px;
    }
  }

  svg {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 2px;
    margin-top: -4px;

    @media (max-width: ${tablet}) {
      width: 14px;
      height: 14px;
      margin-top: -3px;
    }
  }
`

const CloseButtonWrapper = styled.div`
  flex: 0 0 auto;

  @media (min-width: ${notMobile}) {
    display: none;
  }

  button {
    border: none;
    background-color: transparent;
    padding: 6px 2px;
    margin: 0;

    svg {
      height: 18px;
      width: 18px;
    }
  }
`

const BookContent = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobile}) {
    display: none;
  }
`

const MobileBookContent = styled.div`
  display: none;

  @media (max-width: ${mobile}) {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${colors.lightBorder};
    align-items: center;

    div:first-child {
      flex-grow: 1;
      text-align: left;
    }
  }
`

const BookButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 50px;
  border: none;

  @media (max-width: ${tablet}) {
    padding: 12px 33px;
  }

  &:hover {
    opacity: 0.9;
  }
`

const ChargeText = styled.div`
  color: ${colors.fadedText};
  text-align: center;
  margin-top: 10px;
  flex-grow: 1;
`

const PriceText = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  text-align: right;

  span {
    font-size: 12px;
  }

  @media (max-width: ${mobile}) {
    font-size: 18px;

    span {
      font-size: 14px;
    }
  }
`

const DescriptionText = styled.div`
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.lightBorder};
  margin-bottom: 32px;

  @media (max-width: ${tablet}) {
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  @media (max-width: ${mobile}) {
    margin-bottom: 0;
    border-bottom: none;
  }
`

const SubTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 16px;
`

const GenericDetails = styled.div`
  margin-bottom: 32px;

  div:nth-child(2) {
    font-size: 14px;
    line-height: normal;

    svg {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-top: -2px;
      margin-right: 2px;
    }
  }
`

const AmenitiesDetails = styled.div`
  margin-bottom: 32px;

  & > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;

    div {
      flex: 0 0 25%;
      font-size: 12px;
      margin-bottom: 10px;
      padding-right: 10px;

      @media (max-width: 1300px) {
        flex-basis: 33.3%;
      }

      @media (max-width: ${tablet}) {
        flex-basis: 50%;
      }

      svg {
        display: inline-block;
        height: 14px;
        width: 14px;
        margin-top: -3px;
      }
    }
  }
`

const CancelPolicy = styled.div`
  padding: 16px;
  background-color: ${colors.secondaryBackground};
  border-radius: 6px;

  & > div:first-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 10px;
  }

  & > div:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
`

const BookingDetails = styled.div`
  margin-bottom: 32px;
  padding: 16px;
  background-color: ${colors.primaryBackground};
  border-radius: 6px;
  font-size: 14px;

  ${({ isCancelled }) => isCancelled && css`
    background-color: ${colors.secondaryBackground};
  `}

  @media (max-width: ${tablet}) {
    font-size: 12px;
  }

  & > div {
    display: flex;
  }

  & > div:first-child {
    font-weight: 500;
    line-height: 22px;

    & > div:first-child {
      flex-grow: 1;
    }
  }

  & > div:not(:first-child) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px; // match gap value
    gap: 10px;

    div {
      flex: 0 0 auto;
      text-align: left;
      padding-right: 24px;
    }

    a {
      border: none;
      color: ${colors.defaultText};
      text-decoration: underline;
    }
  }

  svg {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 2px;
    margin-top: -4px;

    @media (max-width: ${tablet}) {
      width: 14px;
      height: 14px;
      margin-top: -3px;
    }
  }
`


const DetailView = ({ venue, bookNow, close }) => {
  const onCloseClick = e => {
    e.preventDefault()
    e.stopPropagation()
    close()
  }

  const onBookClick = useBookVenue(venue)

  if (!venue) {
    return null
  }

  const {
    isBooking,
    isCancelled,
    onCancelClick,
    bookingName,
    bookingEmail,
    location,
    dateLabel,
    bookingDateLabel
  } = getBookingDetails(venue)

  return (
    <Background>
      <HeaderContent>
        <TitleContent>
          <Title>{venue.name}</Title>
          <Location>
            <LocationIcon />
            {location}
          </Location>
          <Attributes>
            <div><PeopleIcon />{venue.maxGuests} Guests</div>
            <div><IndoorOutdoorIcon />{capitalize(venue.setting)}</div>
            <div><VenueIcon />{capitalize(venue.venueType)}</div>
          </Attributes>
        </TitleContent>
        <CloseButtonWrapper>
          <button onClick={onCloseClick}>
            <CloseIcon />
          </button>
        </CloseButtonWrapper>
        {!!bookNow && (
          <BookContent>
            <BookButton onClick={onBookClick}>
              Book Now
            </BookButton>
            <ChargeText>You won't be charged yet</ChargeText>
            <PriceText>
              ${Number(venue.dayPrice.replace('$', '')).toLocaleString()}
              <span> / day</span>
            </PriceText>
          </BookContent>
        )}
      </HeaderContent>
      <Photos photos={venue.photos} />
      {!!bookNow && (
        <MobileBookContent>
          <PriceText>
            ${Number(venue.dayPrice.replace('$', '')).toLocaleString()}
            <span> / day</span>
          </PriceText>
          <BookButton onClick={onBookClick}>
            Book Now
          </BookButton>
        </MobileBookContent>
      )}
      {isBooking && (
        <BookingDetails isCancelled={isCancelled}>
          <div>
            <div>Reservation Details</div>
            {onCancelClick && (
              <CancelBooking onCancelClick={onCancelClick} />
            )}
          </div>
          <div>
            <div><CalendarIcon /> {dateLabel}</div>
            <div><CreditCardIcon /> {bookingDateLabel}</div>
          </div>
          <div>
            <div>
              <HostIcon />
              {bookingName}, <a href={`mailto:${bookingEmail}`}>{bookingEmail}</a>
            </div>
          </div>
        </BookingDetails>
      )}
      <DescriptionText>
        {venue.description}
      </DescriptionText>
      {!isBooking && (
        <GenericDetails>
          <SubTitle>Host</SubTitle>
          <div>
            <HostIcon />
            {venue.host.name}
          </div>
        </GenericDetails>
      )}
      {Array.isArray(venue.amenities) && venue.amenities.length > 0 && (
        <AmenitiesDetails>
          <SubTitle>Amenities</SubTitle>
          <div>
            {venue.amenities.map(item => (
              <div key={item}>
                <AmenityIcon type={item} />
                {item}
              </div>
            ))}
          </div>
        </AmenitiesDetails>
      )}
      {!!venue.additionalNotes && (
        <GenericDetails>
          <SubTitle>Additional notes from your host</SubTitle>
          <div>{venue.additionalNotes}</div>
        </GenericDetails>
      )}
      {!!venue.cancellationPolicy && (
        <CancelPolicy>
          <div>Cancellation Policy</div>
          <div>
            <CancellationPolicies cancellationPolicy={venue.cancellationPolicy} />
          </div>
        </CancelPolicy>
      )}
    </Background>
  )
}

export default DetailView