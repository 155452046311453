import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getIsSignedIn, setShowSignIn } from '../store/user'
import { setConfirmVenue } from '../store/search'

export default function useBookVenue(venue) {
  const isSignedIn = useSelector(getIsSignedIn)
  const dispatch = useDispatch()

  return e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isSignedIn) {
      dispatch(setShowSignIn())
      // TODO: ideally, we'd show the confirm search after loggging in
    } else {
      dispatch(setConfirmVenue(venue))
    }
  }
}