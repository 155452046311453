import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CloseIcon,
} from '../../styles'
import {
  ContentWrapper,
  Media,
  Content,
  Header,
  Title,
  SubTitle,
  Field,
  Label,
  StyledInput,
  SubmitButton,
  SwitchModeLink,
  ErrorMessage,
} from './styles'

import {
  getIsFetching,
  setIsFetching,
  setShowSignUp,
  close,
} from '../../../store/user'
import { resetUserPassword } from '../../../firebase/user'


const ForgotModal = () => {
  const dispatch = useDispatch()
  const onCloseClick = () => dispatch(close())
  const onSignUpClick = () => dispatch(setShowSignUp())

  const isFetching = useSelector(getIsFetching)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    if (email.trim() === '') {
      setError('Please fill out all fields')
    } else {
      dispatch(setIsFetching(true))
      resetUserPassword(
        email,
        () => {
          dispatch(setIsFetching(false))
          onCloseClick()
        },
        (error) => {
          dispatch(setIsFetching(false))
          setError(error)
        }
      )
    }
  };

  return (
    <ContentWrapper>
      <Media />
      <Content>
        <Header>
          <div>
            <Title>Forgot Password?</Title>
            <SubTitle>
              Please enter your email to recover your account:
            </SubTitle>
          </div>
          <button onClick={onCloseClick}>
            <CloseIcon />
          </button>
        </Header>
        <form onSubmit={handleSubmit}>
          <Field>
            <Label>Email</Label>
            <StyledInput
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="example@gmail.com"
            />
          </Field>
          {!!error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <SubmitButton
            type="submit"
            disabled={isFetching}
            value={isFetching ? 'Loading...' : 'Reset Password'}
          />
        </form>
        <SwitchModeLink>
          Don't have an account?
          <button onClick={onSignUpClick}>
            Sign up
          </button>
        </SwitchModeLink>
      </Content>
    </ContentWrapper>
  )
};

export default ForgotModal;
