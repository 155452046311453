import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoIosPin } from 'react-icons/io'

const Marker = styled.div`
  position: absolute;
  border-radius: 40px;
  background-color: white;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 6px;
  box-shadow: 2px 2px 4px #707070;
  border: solid 3px white;

  &:hover {
    font-size: 2.5rem;
  }
`;

const SelectedMarker = styled.div`
  position: absolute;
  border-radius: 40px;
  background-color: white;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 6px;
  box-shadow: 2px 2px 4px #707070;
  border: solid 3px #cbad6c;

  &:hover {
    font-size: 2.5rem;
  }
`;

const MapMarker = ({ venue, selectedVenue, setSelectedVenue }) => {
  return (
    <>
      {selectedVenue && selectedVenue.id !== venue.id ? (
        <>
          <Marker
            onClick={() => {
              setSelectedVenue(venue.id);
            }}
          >
            ${venue.dayPrice.replace('$', '')}
          </Marker>
          <IoIosPin style={{ margin: '-30px -20px' }} size={35} color="#EA4335" />
        </>
      ) : (
        <SelectedMarker>${venue.dayPrice.replace('$', '')}</SelectedMarker>
      )}
    </>
  );
};

MapMarker.propTypes = {
  venue: PropTypes.object,
  selectedVenue: PropTypes.object,
  setSelectedVenue: PropTypes.func
};

export default MapMarker;
