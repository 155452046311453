import React from 'react'
import styled, { css } from 'styled-components'

import FavoriteVenue from './FavoriteVenue'
import Thumbnail from './Thumbnail'
import BookNow from './BookNow'
import Attributes from './Attributes'

import {
  tablet,
  mobile,
  notMobile,
  colors,
  headingStyles,
  LocationIcon,
  CalendarIcon,
  CreditCardIcon,
} from '../../styles'
import { getBookingDetails } from '../../helpers'


const CardBackground = styled.button`
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px ${colors.lightBorder};
  background-color: transparent;
  margin: 0 0 16px;
  padding: 0;
  border-radius: 6px;

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &:hover {
    border-color: ${colors.defaultBorder};
  }

  ${({ selected }) => selected && css`
    @media (min-width: ${notMobile}) {
      background-color: ${colors.primaryBackground};
    }
  `}
`


const CardLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  color: ${colors.defaultText};
  text-align: left;

  @media (max-width: ${tablet}) {
    padding: 8px;
  }

  @media (max-width: ${mobile}) {
    display: block;
    padding: 16px;
  }
`

const MobileCardContent = styled.div`
  display: none;

  @media (max-width: ${mobile}) {
    display: flex;
    flex-direction: column;
  }
`

const CardContentWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${mobile}) {
    display: none;
  }
`

const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
`

const CardTitle = styled.div`
  ${headingStyles}
  flex: 1 1 auto;
  font-size: 22px;
  line-height: 28px;
  margin: 2px 0;
`

const CardLocation = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-top: 2px;

  @media (max-width: ${tablet}) {
    ${({ isBookingView }) => isBookingView ? css`
      margin-top: 8px;
    ` : css`
      margin-top: 4px;
    `}
  }

  @media (max-width: ${mobile}) {
    ${({ isBookingView }) => isBookingView ? css`
      margin-top: 9px;
    ` : css`
      margin-top: 6px;
    `}
  }

  svg {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 2px;
    margin-top: -2px;
    fill: ${colors.primaryIcon};
  }
`

const CardReservationData = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;

  @media (max-width: ${tablet}) {
    margin-top: 7px;
  }

  @media (max-width: ${mobile}) {
    margin-top: 9px;
  }

  svg {
    display: inline-block;
    height: 14px;
    width: 14px;
    margin-right: 2px;
    margin-top: -3px;
    fill: ${colors.primaryIcon};
  }
`

const DefaultContent = ({
  venue,
  location,
  isSelected,
}) => {
  return (
    <>
      <CardHeader>
        <CardTitle>{venue.name}</CardTitle>
        <FavoriteVenue venue={venue} venueIsSelected={isSelected} />
      </CardHeader>

      <CardLocation>
        <LocationIcon />
        {location}
      </CardLocation>

      <Attributes venue={venue} />
    </>
  )
}

const BookingContent = ({
  venue,
  location,
  dateLabel,
  bookingCostLabel,
}) => {
  return (
    <>
      <CardHeader>
        <CardTitle>{venue.name}</CardTitle>
      </CardHeader>

      <CardLocation isBookingView>
        <LocationIcon />
        {location}
      </CardLocation>

      <CardReservationData>
        <CalendarIcon />
        {dateLabel}
      </CardReservationData>
      <CardReservationData>
        <CreditCardIcon />
        {bookingCostLabel}
      </CardReservationData>

      <Attributes venue={venue} />
    </>
  )
}


const Venue = ({
  venue,
  selected,
  setSelectedVenue,
}) => {
  const {
    isBooking: isBookingView,
    location,
    ...booking
  } = getBookingDetails(venue)

  const isSelected = !!selected && selected.id === venue.id

  const isDefaultView = !isBookingView

  const content = isBookingView ? (
    <BookingContent
      venue={venue}
      location={location}
      {...booking}
    />
  ) : (
    <DefaultContent
      venue={venue}
      isSelected={isSelected}
      location={location}
    />
  )

  return (
    <CardBackground
      onClick={() => {
        setSelectedVenue(venue.id);
      }}
      selected={isSelected}
    >
      <CardLayout>
        <MobileCardContent>
          {content}
        </MobileCardContent>

        <Thumbnail photos={venue.photos} />

        <CardContentWrapper>
          {content}

          {isDefaultView && (
            <BookNow venue={venue} />
          )}
        </CardContentWrapper>

        {isDefaultView && (
          <MobileCardContent>
            <BookNow venue={venue} />
          </MobileCardContent>
        )}
      </CardLayout>
    </CardBackground>
  )
}

export default Venue
