import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  states,
  venueSettings,
  venueTypes,
  cancellationPolicies,
  amenities,
} from '../../helpers'
import {
  notMobile,
  tablet,
  capitalize,
  colors,
  headingStyles,
} from '../../styles'
import {
  getForm,
  setFormValue,
  setFormErrors,
  getFormErrors,
  setUploadError,
  getUploadError,
  setGeoErrors,
  getGeoErrors,
  setSaveError,
  getSaveError,
  setIsSaving,
  getIsSaving,
  getIsUploading,
  setIsUploading,
} from '../../../store/hosted-venue'
import { getUser } from '../../../store/user'
import {
  geoCode,
  addHostedVenue,
  editHostedVenue,
  addVenuePhoto,
} from '../../../firebase/venues'

import {
  ImagesIcon,
  FieldGroup,
  Field,
  FormLabel,
  Input,
  Textarea,
  Select,
  VenueIcon,
  CalendarIcon,
  CloseIcon,
  WhiteCloseIcon,
} from '../../styles'
import AmenityIcon from '../DetailView/AmenityIcon'
import ExcludeDatesModal from './ExcludeDatesModal'

// NOTE: if you change these, change on functions/index.js & BookModal.js as well.
const DATE_FORMAT = 'YYYY-MM-DD'
const START_DATE_FORMAT = 'MMM D'
const END_DATE_FORMAT = 'MMM D, YYYY'

const MIN_PHOTOS = 3

const formatIntString = value => {
  value = String(value).replace(/[^0-9\.]/g, '')
  if (!value) {
    return ''
  }
  return parseInt(value).toString()
}

const getNextContinuousDate = group => {
  if (!group.length) return null
  const lastDate = group[group.length - 1]
  return moment(`${lastDate}T00:00:00.000`).add(1, 'day').format(DATE_FORMAT)
}
const groupContinuousDates = dates => {
  const lastIndex = dates.length - 1
  const sortedDates = [...dates]
  sortedDates.sort((a, b) => {
    if (a < b) return -1
    if (b < a) return 1
    return 0
  })
  const { groups } = sortedDates
    .reduce(({ groups, group }, date, i) => {
      // if first & last item
      if (i === 0 && i === lastIndex) {
        return { groups: [[date]] }
      }
      // if first item
      if (i === 0) {
        return { groups, group: [date] }
      }
      // handle if continuous or not
      const nextDayOfLastDate = getNextContinuousDate(group)
      if (date !== nextDayOfLastDate) {
        groups.push(group)
        group = [date]
      } else {
        group.push(date)
      }
      // if last item
      if (i === lastIndex) {
        groups.push(group)
        return { groups }
      }
      return { groups, group }
    }, { groups: [], group: [] })
    return groups
}

const _half = Math.ceil(amenities.length / 2)
const amenityOptions = [
  amenities.slice(0, _half),
  amenities.slice(_half),
]

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: ${colors.defaultText};
`

const FormTitle = styled.div`
  ${headingStyles}
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  svg {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-top: -6px;
  }
`

const FormTitleText = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
`

const CloseButtonWrapper = styled.div`
  flex: 0 0 auto;

  @media (min-width: ${notMobile}) {
    display: none;
  }

  button {
    border: none;
    background-color: transparent;
    margin: 0;

    svg {
      height: 18px;
      width: 18px;
    }
  }
`

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin: 12px 0;

  &:first-of-type {
    margin-top: 0;
  }
`

const Photos = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & > div {
    flex: 0 0 100px;
    height: 100px;
    position: relative;

    &.empty-photo {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.lightBackground};
      border-radius: 8px;
    }

    & > button {
      position: absolute;
      top: -8px;
      right: -8px;
      padding: 6px;
      margin: 0;
      background-color: ${colors.primaryButton};
      border: 4px solid white;
      border-radius: 30px;

      svg {
        display: block;
        width: 12px;
        height: 12px;
        fill: white;
      }
    }

    img {
      height: 100px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    & > span svg {
      display: block;
      height: 32px;
      width: 32px;
      opacity: 0.3;
    }
  }
`

const  FormButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;

  button + button {
    margin-left: 16px;
  }
`

const InputButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 16px;
  border: none;
  width: max-content;

  ${({ ghostStyle }) => ghostStyle && css`
    color: ${colors.primaryButton};
    background-color: transparent;
  border: 1px solid ${colors.primaryButton};
  `}

  ${({ hasError }) => hasError && css`
    background-color: ${colors.errorColor};
  `}

  @media (max-width: ${tablet}) {
    padding: 12px 33px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`

const TextButton = styled.button`
  font-weight: 400;
  color: ${colors.defaultText};
  background-color: transparent;
  font-size: 14px;
  border: none;
  width: max-content;
  padding: 5px;
  flex-shrink: 0;

  &:hover {
    text-decoration: underline;
  }
`

const AvailableDates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
`

const DisabledDates = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  gap: 6px;
  font-size: 14px;
  margin-top: -4px;
  margin-bottom: 4px;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    svg {
      display: block;
      width: 17px;
      height: 17px;
      fill: ${colors.defaultText};
    }

    button {
      padding: 6px;
      margin: 0;
      background-color: ${colors.primaryButton};
      border: 4px solid white;
      border-radius: 30px;

      svg {
        width: 10px;
        height: 10px;
        fill: white;
      }
    }
  }
`

const FieldWithPadding = styled(Field)`
  margin-top: 16px;
`

const AmenityGroup = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 4px;
`

const Amenity = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-right: 6px;
  cursor: pointer;

  input {
    display: inline-block;
    margin-right: 10px;
  }

  svg {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-right: 8px;
  }
`

const AmenityName = styled.span`
  display: inline-block;
  font-size: 14px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 108px; // clear bottom footer
  gap: 24px;
`

const SaveButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 50px;
  border: none;
  width: max-content;
  flex-shrink: 0;

  @media (max-width: ${tablet}) {
    padding: 12px 33px;
  }

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`

const WarningMessage = styled.div`
  font-weight: 500;
  font-size: 13px;
  flex-grow: 1;
  flex-shrink: 1;
  margin-bottom: 8px;
  padding: 16px;
  background-color: ${colors.lightBackground};
`

const ErrorMessage = styled.div`
  color: ${colors.errorColor};
  font-weight: 500;
  font-size: 13px;
  flex-grow: 1;
  flex-shrink: 1;
`

const ErrorHint = styled.span`
  display: inline-block;
  margin-left: 16px;
  color: ${colors.errorColor};
  font-weight: 400;
  font-size: 12px;
`


const DetailView = ({
  isAdminMode,
  isEditMode,
  id,
  clearSelected,
}) => {
  const dispatch = useDispatch()
  const form = useSelector(getForm)
  const errors = useSelector(getFormErrors)
  const hasErrors = Object.keys(errors).length > 0
  const hasGeoErrors = useSelector(getGeoErrors)
  const hasSaveError = useSelector(getSaveError)
  const uploadError = useSelector(getUploadError)
  const isSaving = useSelector(getIsSaving)
  const isUploading = useSelector(getIsUploading)
  const user = useSelector(getUser)
  const history = useHistory()

  const [showDatesModal, setDatesModal] = useState(false)

  const uploadRef = useRef(null)

  // build clean excluded dates arry; & remove any dates from the past
  const today = moment().format(DATE_FORMAT)
  const excludedDates = (
    Array.isArray(form.excludedDates) ? form.excludedDates : []
  ).filter(date => date > today)
  // build disabled dates for date-picker
  const disabledDates = excludedDates.map(date => new Date(`${date}T00:00:00.000-06:00`))
  // group disabled dates by continuous dates
  const continuousDisabledDates = groupContinuousDates(excludedDates)

  const onPhotoError = error => {
    dispatch(setUploadError(error || 'Failed to upload photo'))
  }
  const onPhotoSuccess = photo => {
    if (photo && photo.ref && photo.url) {
      dispatch(setFormValue({
        photos: [
          ...form.photos,
          photo
        ]
      }))
    }
  }
  const onPhotoSelect = async e => {
    const files = e.target.files
    if (!files || !files.length) {
      return
    }
    dispatch(setIsUploading(true))
    const photo = files[0]
    const maxFileSize = 10_000_000
    const error = (
      maxFileSize && Number(photo.size) > Number(maxFileSize)
      ? `File exceeds ${Math.ceil(maxFileSize / 1000 / 1000)} MB`
      : !photo.name
      ? 'File missing filename'
      : !photo.type.startsWith('image')
      ? 'Unsupported file type'
      : null
    )
    if (error) {
      return onPhotoError(error)
    }
    addVenuePhoto(
      { photo },
      onPhotoSuccess,
      onPhotoError,
    )
  }

  const onSaveClick = async e => {
    e.preventDefault()
    e.stopPropagation()
    dispatch(setIsSaving(true))

    const props = JSON.parse(JSON.stringify(form))

    // clean data
    props.name = props.name.trim()
    props.description = props.description.trim()
    props.address_street = props.address_street.trim()
    props.address_city = props.address_city.trim()
    props.address_state = props.address_state.trim()
    props.address_zip = props.address_zip.trim()
    props.additionalNotes = props.additionalNotes.trim()
    props.amenities = (props.amenities || []).filter(v => !!v)
    props.dayPrice = formatIntString(props.dayPrice)
    props.maxGuests = formatIntString(props.maxGuests)
    props.photos = props.photos.filter(photo => photo.url)
    props.disabled = props.disabled === true
    delete props.verified // `verified` is only used for form logic, don't change value

    // validate
    const requiredFields = [
      'name',
      'description',
      'setting',
      'venueType',
      'address_street',
      'address_city',
      'address_state',
      'address_zip',
      'amenities',
      'dayPrice',
      'maxGuests',
      'cancellationPolicy',
    ]
    const errors = requiredFields.reduce((errors, field) => {
      const value = props[field]
      if (!value) {
        errors[field] = true
      } else if (Array.isArray(value) && !value.length) {
        errors[field] = true
      }
      return errors
    }, {})
    if (!Array.isArray(props.photos) || props.photos.length < MIN_PHOTOS) {
      errors.photos = true
      dispatch(setUploadError(`Please upload a minimum of ${MIN_PHOTOS} photos`))
    }
    if (Object.keys(errors).length > 0) {
      dispatch(setFormErrors(errors))
      return
    }

    props.address = {
      street: props.address_street,
      city: props.address_city,
      state: props.address_state,
      zip: props.address_zip,
      country: props.address_country,
    }
    delete props.address_street
    delete props.address_city
    delete props.address_state
    delete props.address_zip
    delete props.address_country

    try {
      const [ lat, lng, exactMatch ] = await geoCode(encodeURIComponent(
        `${props.address.street}, ${props.address.city}, ${props.address.state}, ${props.address.country}`
      ))
      if (!exactMatch) {
        dispatch(setGeoErrors(true))
        return
      }
      props.location = { lat, lng }
    } catch (e) {
      dispatch(setGeoErrors(true))
      return
    }

    if (isEditMode || isAdminMode) {
      editHostedVenue(
        { id, props },
        () => window.location.reload(), // refetch our newest venues
        () => dispatch(setSaveError(true))
      )
    } else {
      Object.assign(props, {
        verified: false,
        userId: user.id,
        host: {
          email: user.email,
          name: `${user.firstName} ${user.lastName}`,
          phoneNumber: user.phoneNumber || "",
          type: 'Individual'
        },
        // legacy props
        bookings: [],
        availableDates: [],
        operatingHours: {},
      })
      addHostedVenue(
        { props },
        () => window.location.reload(), // refetch our newest venues
        () => dispatch(setSaveError(true))
      )
    }

    if (window.location.hostname === 'localhost') {
      console.log(JSON.stringify(props, null, 2))
    }
  }

  const onCancelSave = e => {
    e.preventDefault()
    e.stopPropagation()
    if (clearSelected) {
      clearSelected()
    } else {
      history.push('/')
    }
  }

  return (
    <FormContent>
      <FormTitle>
        <VenueIcon />
        <FormTitleText>{isAdminMode ? 'Review' : isEditMode ? 'Edit' : 'Add New'} Venue</FormTitleText>
        <CloseButtonWrapper>
          <button onClick={onCancelSave}>
            <CloseIcon />
          </button>
        </CloseButtonWrapper>
      </FormTitle>
      <Title>Venue Photos</Title>
      <Photos>
        {form.photos.length > 0 && form.photos.map(photo => (
          <div key={photo.url}>
            <button
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                dispatch(setFormValue({
                  photos: form.photos.filter(_photo => _photo.url !== photo.url)
                }))
              }}
            >
              <WhiteCloseIcon />
            </button>
            <img src={photo.thumbnail || photo.url} />
          </div>
        ))}
        {form.photos.length < MIN_PHOTOS && Array.from(Array(MIN_PHOTOS - form.photos.length)).map((_, i) => (
          <div
            key={i}
            className="empty-photo"
          >
            <ImagesIcon />
          </div>
        ))}
      </Photos>
      <input
        ref={uploadRef}
        type="file"
        accept="image/*"
        multiple={false}
        style={{ visibility: 'hidden', height: 0 }}
        onChange={onPhotoSelect}
      />
      <FormButtonWrapper>
        <InputButton
          hasError={errors.photos}
          onClick={() => {
            uploadRef.current.click()
          }}
          disabled={isUploading}
        >
          {isUploading ? 'Uploading...' : 'Add Photos'}
        </InputButton>
        {uploadError && (
          <ErrorHint>{uploadError}</ErrorHint>
        )}
      </FormButtonWrapper>

      <Title>Venue Details</Title>
      <Field hasError={errors.name}>
        <FormLabel>Title</FormLabel>
        <Input
          placeholder="Venue name"
          value={form.name}
          onChange={e => dispatch(setFormValue({ name: e.target.value }))}
        />
      </Field>
      <Field hasError={errors.description}>
        <FormLabel>Description</FormLabel>
        <Textarea
          placeholder="Venue explanation"
          value={form.description}
          onChange={e => dispatch(setFormValue({ description: e.target.value }))}
        />
      </Field>
      <FieldGroup>
        <Field hasError={errors.setting}>
          <FormLabel>Setting</FormLabel>
          <Select
            value={form.setting}
            onChange={e => {
              dispatch(setFormValue({ setting: e.target.value }))
            }}
          >
            {venueSettings.map(type => (
              <option key={type} value={type}>{capitalize(type)}</option>
            ))}
          </Select>
        </Field>
        <Field hasError={errors.venueType}>
          <FormLabel>Type</FormLabel>
          <Select
            value={form.venueType}
            onChange={e => {
              dispatch(setFormValue({ venueType: e.target.value }))
            }}
          >
            {venueTypes.map(type => (
              <option key={type} value={type}>{capitalize(type)}</option>
            ))}
          </Select>
        </Field>
      </FieldGroup>

      <Title>
        Amenities
        {errors.amenities && (
          <ErrorHint>Please select venue amenities</ErrorHint>
        )}
      </Title>
      <FieldGroup>
        {amenityOptions.map((amenities, index) => (
          <AmenityGroup key={index}>
            {amenities.map(value => (
              <Amenity key={value}>
                <input
                  type="checkbox"
                  value={value}
                  checked={form.amenities.includes(value)}
                  onChange={e => {
                    const { checked } = e.target
                    const index = form.amenities.indexOf(value)
                    if (checked && index === -1) {
                      dispatch(setFormValue({
                        amenities: [
                          ...form.amenities,
                          value,
                        ]
                      }))
                    } else if (!checked && index >= 0) {
                      dispatch(setFormValue({
                        amenities:
                          form.amenities.filter(_value => value !== _value)
                      }))
                    }
                  }}
                />
                <AmenityIcon type={value} />
                <AmenityName>{value}</AmenityName>
              </Amenity>
            ))}
          </AmenityGroup>
        ))}
      </FieldGroup>

      <Title>Venue Address</Title>
      <Field hasError={errors.address_street}>
        <FormLabel>Street</FormLabel>
        <Input
          placeholder="100 W Park St"
          value={form.address_street}
          onChange={e => dispatch(setFormValue({ address_street: e.target.value }))}
        />
      </Field>
      <FieldGroup>
        <Field hasError={errors.address_city}>
          <FormLabel>City</FormLabel>
          <Input
            placeholder="Salt Lake City"
            value={form.address_city}
            onChange={e => dispatch(setFormValue({ address_city: e.target.value }))}
          />
        </Field>
        <Field hasError={errors.address_state}>
          <FormLabel>State</FormLabel>
          <Select
            value={form.address_state}
            onChange={e => {
              dispatch(setFormValue({ address_state: e.target.value }))
            }}
          >
            <option value=""></option>
            {states.map(state => (
              <option key={state} value={state}>{state}</option>
            ))}
          </Select>
        </Field>
        <Field hasError={errors.address_zip}>
          <FormLabel>Zip Code</FormLabel>
          <Input
            placeholder="00000"
            value={form.address_zip}
            onChange={e => dispatch(setFormValue({ address_zip: e.target.value }))}
          />
        </Field>
      </FieldGroup>

      <Title>Pricing Details</Title>
      <FieldGroup>
        <Field hasError={errors.dayPrice}>
          <FormLabel>Daily Rate</FormLabel>
          <Input
            placeholder="$1,000"
            value={form.dayPrice}
            onChange={e => {
              dispatch(setFormValue({ dayPrice: e.target.value }))
            }}
          />
        </Field>
        <Field hasError={errors.maxGuests}>
          <FormLabel>Max Guests</FormLabel>
          <Input
            placeholder="200"
            value={form.maxGuests}
            onChange={e => dispatch(setFormValue({ maxGuests: e.target.value }))}
          />
        </Field>
      </FieldGroup>
      <Field>
        <FormLabel>Cancellation Policy</FormLabel>
        <Select
          value={form.cancellationPolicy}
          onChange={e => {
            dispatch(setFormValue({ cancellationPolicy: e.target.value }))
          }}
        >
          {cancellationPolicies.map(({ name, timeline }) => (
            <option key={name} value={name}>
              Cancellations before {timeline} will receive a full refund
            </option>
          ))}
        </Select>
      </Field>

      <Title>
        Availability
      </Title>
      <AvailableDates>
        {form.disabled ? (
          <WarningMessage>
            Your venue will not appear in search or receive new reservations.
          </WarningMessage>
        ) : (
          <>
            <FormLabel>Select any dates your venue is <strong>unavailable</strong> (optional)</FormLabel>
            {continuousDisabledDates.length > 0 && (
              <DisabledDates>
                {continuousDisabledDates.map((group, i) => {
                  if (!group || !group.length) {
                    return null
                  }
                  const button = (
                    <button
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        dispatch(setFormValue({
                          excludedDates: excludedDates.filter(date => !group.includes(date))
                        }))
                      }}
                    >
                      <WhiteCloseIcon />
                    </button>
                  )
                  if (group.length === 1) {
                    return (
                      <div key={i}>
                        {button}
                        <CalendarIcon />
                        <div>{moment(`${group[0]}T00:00:00.000`).format(END_DATE_FORMAT)}</div>
                      </div>
                    )
                  } else {
                    return (
                      <div key={i}>
                        {button}
                        <CalendarIcon />
                        <div>
                          {moment(`${group[0]}T00:00:00.000`).format(START_DATE_FORMAT)}
                          {' '}&ndash;{' '}
                          {moment(`${group[group.length - 1]}T00:00:00.000`).format(END_DATE_FORMAT)}
                        </div>
                      </div>
                    )
                  }
                })}
              </DisabledDates>
            )}
          </>
        )}
        <FormButtonWrapper>
          <InputButton
            onClick={() => {
              setDatesModal(true)
            }}
            disabled={form.disabled}
            ghostStyle={form.disabled}
          >
            Add Unavailable Dates
          </InputButton>
          {(!!form.disabled || !!form.verified) && (
            <InputButton
              onClick={() => {
                dispatch(setFormValue({ disabled: !form.disabled }))
              }}
              ghostStyle={!form.disabled}
            >
              {form.disabled ? 'Add Venue to Search' : 'Exclude Venue from Search'}
            </InputButton>
          )}
        </FormButtonWrapper>
      </AvailableDates>
      {showDatesModal && (
        <ExcludeDatesModal
          onCancel={() => setDatesModal(false)}
          onPick={dateRange => {
            // determine new excluded dates
            const { startDate, endDate } = dateRange
            const end = moment(endDate)
            const start = moment(startDate)
            const nextRange = [ start.format(DATE_FORMAT) ]
            let next = start
            while (next.valueOf() !== end.valueOf()) {
              next = moment(next).add(1, 'day')
              nextRange.push(next.format(DATE_FORMAT))
            }
            let nextExcludedDates = [
              ...excludedDates,
              ...nextRange
            ]
            // sort in reverse order to speed up search
            nextExcludedDates.sort((a, b) => {
              if (a < b) return 1
              if (b < a) return -1
              return 0
            })
            // remove duplicates
            nextExcludedDates = nextExcludedDates.filter((value, i, arr) => (
              i === 0 || value !== arr[i - 1]
            ))
            // save
            dispatch(setFormValue({ excludedDates: nextExcludedDates }))
            setDatesModal(false)
          }}
          disabledDates={disabledDates}
        />
      )}

      <FieldWithPadding>
        <FormLabel>Additional Notes</FormLabel>
        <Textarea
          placeholder="Optional notes"
          value={form.additionalNotes}
          onChange={e => dispatch(setFormValue({ additionalNotes: e.target.value }))}
        />
      </FieldWithPadding>

      <ButtonWrapper>
        {hasErrors && (
          <ErrorMessage>Please fill out all required fields.</ErrorMessage>
        )}
        {hasGeoErrors && (
          <ErrorMessage>We could not locate the venue from the provided address.</ErrorMessage>
        )}
        {hasSaveError && (
          <ErrorMessage>Failed to save venue. Please try again.</ErrorMessage>
        )}
        <TextButton onClick={onCancelSave}>
          Cancel
        </TextButton>
        <SaveButton
          onClick={onSaveClick}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save Venue'}
        </SaveButton>
      </ButtonWrapper>
    </FormContent>
  )
}

export default DetailView