import { createSlice, createSelector } from '@reduxjs/toolkit'


////////////////////////////
//   Reducers & Actions   //
////////////////////////////

const initialState = {
  // Logged out user
  user: {
    id: null,
  },
  isSaving: false,
  isFetching: false,
  showSignIn: false,
  showSignUp: false,
  showForgot: false,
  showBecomeHost: false,
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      if (!action.payload) {
        state.user = initialState.user
      } else {
        state.user = action.payload
      }
    },
    setIsSaving(state, action) {
      state.isSaving = action.payload === true
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload === true
    },
    setShowSignIn(state) {
      state.showSignIn = true
      state.showSignUp = false
      state.showForgot = false
      state.showBecomeHost = false
    },
    setShowSignUp(state) {
      state.showSignUp = true
      state.showSignIn = false
      state.showForgot = false
      state.showBecomeHost = false
    },
    setShowForgot(state) {
      state.showSignUp = false
      state.showSignIn = false
      state.showForgot = true
      state.showBecomeHost = false
    },
    setShowBecomeHost(state) {
      state.showSignUp = false
      state.showSignIn = false
      state.showForgot = false
      state.showBecomeHost = true
    },
    close(state) {
      state.showSignIn = false
      state.showSignUp = false
      state.showForgot = false
      state.showBecomeHost = false
    },
  },
})

export default user.reducer

export const {
  setUser,
  setIsSaving,
  setIsFetching,
  setShowSignIn,
  setShowSignUp,
  setShowForgot,
  setShowBecomeHost,
  close,
} = user.actions


////////////////////////////
//   Selector Functions   //
////////////////////////////

const selectSelf = state => state.user

export const getIsSignedIn = createSelector(selectSelf, state => !!state.user.id)
export const getUser = createSelector(selectSelf, state => state.user)
export const getIsSaving = createSelector(selectSelf, state => state.isSaving)
export const getIsFetching = createSelector(selectSelf, state => state.isFetching)
export const getShowSignIn = createSelector(selectSelf, state => state.showSignIn)
export const getShowSignUp = createSelector(selectSelf, state => state.showSignUp)
export const getShowForgot = createSelector(selectSelf, state => state.showForgot)
export const getShowBecomeHost = createSelector(selectSelf, state => state.showBecomeHost)
export const getIsValidHost = createSelector(selectSelf, state => (
  state.user.hasVenues && state.user.hasPaymentInfo
))
export const getHasHostVenues = createSelector(selectSelf, state => state.user.hasVenues === true)
