import React from 'react'

import {
  Field,
  Label,
  StyledInput,
} from './styles'

function prettyGuests(value) {
  return value ? value.toLocaleString() : value
}

export default function Guests({
  numGuests,
  setNumGuests,
}) {
  const onNumChangeHandler = event => {
    const value = Number(String(event.target.value).replace(/[^0-9]/g, ''))
    setNumGuests(value || '')
  };

  return (
    <Field mobileHide>
      <Label>Guests</Label>
      <StyledInput
        value={prettyGuests(numGuests)}
        onChange={onNumChangeHandler}
        placeholder="Total guests"
      />
    </Field>
  );
}
