import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import { getUser, setShowSignIn } from '../../../../../store/user'
import { getConfirmVenue } from '../../../../../store/search'
import { makeCheckoutLink } from '../../../../../firebase/stripe'
import { makeDisabledDates } from './helpers'
import { isAvailable, countDays } from '../../../../helpers'
import { colors, CloseIcon, SmallPeopleIcon } from '../../../../styles'

import Calendar from './Calendar'

// NOTE: if you change these, change on functions/index.js & DetailForm.js as well.
const DATE_FORMAT = 'YYYY-MM-DD'
const MAX_DAYS = 14

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Calendar} {
    align-self: center;
  }
`

const VenueContent = styled.div`
  display: flex;
  justify-content: none;
  align-items: flex-start;
  margin-bottom: 24px;

  margin-top: -5px;
  padding-bottom: 22px;
  border-bottom: 1px solid ${colors.mediumBorder};
  margin-bottom: 28px;

  & > img {
    flex-shrink: 0;
    flex-grow: 0;
    height: 77px;
    width: 77px;
    object-fit: cover;
    border-radius: 4px;
  }

  & > div {
    flex-shrink: 1;
    flex-grow: 1;
    padding: 0 16px;
    max-width: 251px;
  }

  & > button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: -5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SubTitle = styled.div`
  color: ${colors.fadedText};
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;

  svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 1px;
    margin-top: -2px;
  }
`

const PriceTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 16px;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;

  span {
    font-size: 12px;
  }
`

const ErrorMessage = styled.div`
  margin: -5px 0.833em 16px;
  color: red;
  text-align: center;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  border-top: 1px solid ${colors.mediumBorder};
  padding-top: 28px;
`

const CancelButton = styled.button`
  font-weight: 500;
  color: ${colors.defaultText};
  background-color: transparent;
  font-size: 14px;
  padding: 0;
  padding-bottom: 2px;
  border: none;
  border-bottom: 1px solid ${colors.defaultText};
  line-height: 1;
`

const SubmitButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 0;
  width: 166px;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    opacity: 0.9;
  }
`


export default function BookModal({
  dateRange,
  onDatePick: _onDatePick,
  onCancel: _onCancel,
  onSubmit: _onSubmit,
}) {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const confirmVenue = useSelector(getConfirmVenue)
  const showConfirmVenue = !!confirmVenue

  const maxGuests = confirmVenue?.maxGuests || 0
  const title = confirmVenue?.name || ''
  const price = Number(confirmVenue?.dayPrice.replace('$', '')).toLocaleString()
  const image =
    Array.isArray(confirmVenue?.photos) && confirmVenue?.photos.length
    ? confirmVenue?.photos[0].thumbnail
    : null

  const disabledDates = makeDisabledDates(confirmVenue)
  const days = countDays(dateRange)

  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState('')

  const onDatePick = (...args) => {
    setError('')
    _onDatePick(...args)
  }
  const onCancel = (...args) => {
    setError('')
    _onCancel(...args)
  }
  const onSubmit = (...args) => {
    setError('')
    _onSubmit(...args)
  }

  const onBookConfirm = async () => {
    // validate dates
    if (!dateRange.startDate || !dateRange.endDate) {
      setError('Please enter date of reservation')
      return
    }
    const start = moment(dateRange.startDate)
    const end = moment(dateRange.endDate)
    if (days > MAX_DAYS) {
      setError(`Reservations must be for less than ${MAX_DAYS + 1} days`)
      return
    }
    if (!isAvailable(dateRange, confirmVenue)) {
      setError(`Venue is not available within your date selection`)
      return
    }
    // navigate to stripe checkout
    setError('')
    setIsProcessing(true)
    const { url, error, unauthenticated } = await makeCheckoutLink({
      user,
      venue: confirmVenue,
      days,
      start: start.format(DATE_FORMAT),
      end: end.format(DATE_FORMAT),
    })
    setIsProcessing(false)
    if (unauthenticated) {
      onCancel()
      dispatch(setShowSignIn())
    } else if (error) {
      setError('Could not complete. Please try again.')
    } else {
      onSubmit()
      window.location.href = url
    }
  }

  return (
    <Modal
      centered
      className="date-picker"
      show={showConfirmVenue}
      onHide={onCancel}
    >
      <Modal.Body>
        <ContentWrapper>
          <VenueContent>
            {image && <img src={image} alt={title} />}
            <div>
              <Title>{title}</Title>
              <SubTitle>
                <SmallPeopleIcon />
                Up to {maxGuests} guests
              </SubTitle>
              <PriceTitle>
                <div>{days} <span>day{days > 1 ? 's ' : ' '}</span></div>
                <div>${price} <span>/ day</span></div>
              </PriceTitle>
            </div>
            <button onClick={onCancel}>
              <CloseIcon />
            </button>
          </VenueContent>
          <Calendar
            dateRange={dateRange}
            onDatePick={onDatePick}
            disabledDates={disabledDates}
          />
          {!!error && (
            <ErrorMessage>{error}</ErrorMessage>
          )}
          <Buttons>
            <CancelButton onClick={onCancel}>
              Cancel
            </CancelButton>
            <SubmitButton
              disabled={isProcessing}
              onClick={onBookConfirm}
            >
              {isProcessing ? 'Loading...' : 'Book Now'}
            </SubmitButton>
          </Buttons>
        </ContentWrapper>
      </Modal.Body>
    </Modal>
  )
}
