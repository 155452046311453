import React, { useLayoutEffect, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Loading from './Loading'

import { getUser } from '../store/user'

export default function RequireUser({ children }) {
  const user = useSelector(getUser)
  const [loadingUser, setLoadingUser] = useState(null)
  const history = useHistory()
  const isLoggedIn = !!(user && user.id)

  useLayoutEffect(() => {
    // if user doesn't load in N seconds, assume logged out & redirect
    if (!isLoggedIn && !loadingUser) {
      setLoadingUser(setTimeout(() => {
        setLoadingUser(null)
        history.push('/')
      }, 5000))
    }

    // when user loads, clear timeout
    if (isLoggedIn && loadingUser) {
      setLoadingUser(null)
      clearTimeout(loadingUser)
    }
  }, [isLoggedIn])

  // clear timeout if exiting the page
  useEffect(() => {
    return () => {
      if (loadingUser) {
        clearTimeout(loadingUser)
      }
    }
  }, [loadingUser])

  if (!isLoggedIn) {
    return <Loading />
  }

  return children
}