import React from 'react'
import moment from 'moment'

// default styles
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
// custom styles
import './datepicker-styles.css'

import { DateRange } from 'react-date-range'

import { colors } from '../../../../styles'

function formatDate(d) {
  let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

  if (month.length < 2)
      month = '0' + month
  if (day.length < 2)
      day = '0' + day

  return [year, month, day].join('-')
}


function Calendar({
  dateRange,
  onDatePick,
  disabledDates = [],
}) {
  const minDate = moment().add(1, 'day').toDate()
  const maxDate = moment().add(3, 'year').toDate()

  return (
    <DateRange
      dateDisplayFormat="M / d / yyyy"
      ranges={[{
        ...dateRange,
        key: 'selection',
        color: colors.primaryBackground,
      }]}
      onChange={onDatePick}
      minDate={minDate}
      maxDate={maxDate}
      disabledDates={disabledDates}
    />
  )
}

export default Calendar
