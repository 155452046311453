import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Field } from '../styles'
import DateLabel from './DateLabel'
import SelectDatesModal from './SelectDatesModal'
import BookModal from './BookModal'

import {
  closeSearch,
} from '../../../../../store/search'

export default function DatePicker({
  dateRange: lastConfirmedDateRange,
  setDateRange: confirmDateRange,
}) {
  const dispatch = useDispatch()
  const [dateRange, setDateRange] = useState(lastConfirmedDateRange)

  const onDatePick = ranges => {
    setDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    })
  }

  const onCancel = () => {
    setDateRange(lastConfirmedDateRange)
    dispatch(closeSearch())
  }

  const onSubmit = () => {
    confirmDateRange(dateRange)
    dispatch(closeSearch())
  }

  return (
    <Field mobileHide>
      <DateLabel
        dateRange={dateRange}
      />

      <SelectDatesModal
        dateRange={dateRange}
        onDatePick={onDatePick}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />

      <BookModal
        dateRange={dateRange}
        onDatePick={onDatePick}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </Field>
  );
}
