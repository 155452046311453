import React, { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import DetailForm from '../HostView/DetailForm'

import { setInitialState } from '../../../store/hosted-venue'

const DetailWrapper = styled.div`
  padding: 32px 24px;
  min-width: 460px;
  margin: 0 auto;
  max-width: 640px;
  width: 100%;
`


function AdminVenueReview({ venue }) {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (venue) {
      dispatch(setInitialState(venue))
    }
  }, [])

  if (!venue) {
    return null
  }

  return (
    <DetailWrapper>
      <DetailForm
        isAdminMode
        id={venue.id}
        clearSelected={() => {
          window.close()
        }}
      />
    </DetailWrapper>
  )
}

export default AdminVenueReview
