import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Modal from 'react-bootstrap/Modal'

import Calendar from '../../Shared/NewHeader/Search/DatePicker/Calendar'

import { setRange } from '../../Shared/NewHeader/Search/DatePicker/helpers'
import { colors, CloseIcon } from '../../styles'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Calendar} {
    align-self: center;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  margin-top: -5px;
  padding-bottom: 21px;
  border-bottom: 1px solid ${colors.mediumBorder};
  margin-bottom: 26px;

  & > div {
    padding-right: 20px;

    & > span {
      font-weight: 500;
    }
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  border-top: 1px solid ${colors.mediumBorder};
  padding-top: 28px;
`

const CancelButton = styled.button`
  font-weight: 500;
  color: ${colors.defaultText};
  background-color: transparent;
  font-size: 14px;
  padding: 0;
  padding-bottom: 2px;
  border: none;
  border-bottom: 1px solid ${colors.defaultText};
  line-height: 1;
`

const SubmitButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 69px;
  border: none;

  &:hover {
    opacity: 0.9;
  }
`


const ExcludeDatesModal = ({ onPick, onCancel, disabledDates }) => {
  const [dateRange, setDateRange] = useState(setRange(null, null))
  const onDatePick = ranges => {
    setDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    })
  }

  const startDate = moment(dateRange.startDate).format('MMM D')
  const endDate = moment(dateRange.endDate).format('MMM D, YYYY')

  return (
    <Modal
      centered
      className="date-picker"
      show
      onHide={onCancel}
    >
      <Modal.Body>
        <ContentWrapper>
          <Title>
            <div>
              Venue will be unavailable for new reservations
              {dateRange.startDate.valueOf() === dateRange.endDate.valueOf()
                ? <> on <span>{endDate}</span></>
                : <> between <span>{startDate} &ndash; {endDate}</span></>
              }
            </div>
            <button onClick={onCancel}>
              <CloseIcon />
            </button>
          </Title>
          <Calendar
            dateRange={dateRange}
            onDatePick={onDatePick}
            disabledDates={disabledDates}
          />
          <Buttons>
            <CancelButton onClick={onCancel}>
              Cancel
            </CancelButton>
            <SubmitButton onClick={() => {
              onPick(dateRange)
            }}>
              Save
            </SubmitButton>
          </Buttons>
        </ContentWrapper>
      </Modal.Body>
    </Modal>
  )
}

export default ExcludeDatesModal