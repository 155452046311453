/* eslint-disable react/no-children-prop */
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'

import Home from './pages/PageHome'
import Profile from './pages/PageProfile'
import Confirmation from './pages/PageConfirmation'
import Logout from './pages/PageLogout'
import SearchVenues from './pages/PageSearchVenues'
import FavoriteVenues from './pages/PageFavoriteVenues'
import ReservedVenues from './pages/PageReservedVenues'
import HostedVenues from './pages/PageHostedVenues'
import HostReservations from './pages/PageHostReservations'
import HostStripeRedirect from './pages/PageHostStripeRedirect'
import AdminReviewVenue from './pages/PageAdminReviewVenue'
// import Reservations from './pages/PageReservations.jsx'
// import AddEditVenue from './pages/PageAddEditVenue.jsx'
// import HostVenue from './pages/PageHostVenues.jsx'
// import BookVenue from './pages/PageBookVenue.jsx'
// import StripeRefresh from './pages/PageStripeRefresh.jsx'
// import Dashboard from './pages/PageStripeDashboard.jsx'
// import HostDashboard from './pages/PageHostDashboard.jsx'
import Admin from './pages/PageAdmin.jsx'

const App = () => {
  return (
    <Router>
      <Switch>
        {/* <Route path="/reservations/:tabs/:uid" children={<Reservations />} /> */}
        {/* <Route path="/dashboard" children={<HostDashboard />} /> */}
        <Route path="/profile" children={<Profile />} />
        {/* <Route path="/editvenue/:id" children={<AddEditVenue />} /> */}
        {/* <Route path="/editvenue/" children={<AddEditVenue />} /> */}
        {/* <Route path="/hostvenues" children={<HostVenue />} /> */}
        {/* <Route path="/bookvenue/:id" children={<BookVenue />} /> */}
        <Route path="/search" children={<SearchVenues />} />
        <Route path="/favorites" children={<FavoriteVenues />} />
        <Route path="/reservations" children={<ReservedVenues />} />
        <Route path="/admin/review/:id" children={<AdminReviewVenue />} />
        <Route path="/host/venues" children={<HostedVenues />} />
        <Route path="/host/reservations" children={<HostReservations />} />
        <Route path="/host/stripe" children={<HostStripeRedirect />} />
        <Route path="/confirmation" children={<Confirmation />} />
        {/* <Route path="/stripe/reauth" exact children={<StripeRefresh />} /> */}
        {/* <Route path="/stripe/dashboard" exact children={<Dashboard />} /> */}
        <Route path="/admin/home" exact children={<Admin />} />
        <Route path="/logout" exact children={<Logout />} />
        <Route path="/" children={<Home />} />
      </Switch>
    </Router>
  );
};

export default App;
