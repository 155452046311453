import React from 'react'
import styled from 'styled-components'

import Venue from './Venue'
import VenueSmall from './VenueSmall'
import Pagination from './Pagination'


export const Venues = styled.ul`
  margin: 0;
  padding: 0;
`


const ListView = ({
  venueList,
  selected,
  setSelectedVenue,
  totalPages,
  currentPage,
  setCurrentPage,
  isHostView = false
}) => (
  <>
    <Venues>
      {venueList.map(venue => isHostView ? (
        <VenueSmall
          key={venue.id}
          venue={venue}
          selected={selected}
          setSelectedVenue={setSelectedVenue}
        />
      ) : (
        <Venue
          key={venue.id}
          venue={venue}
          selected={selected}
          setSelectedVenue={setSelectedVenue}
        />
      ))}
    </Venues>
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  </>
)

export default ListView
