import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HamburgerIcon, VenueIcon } from '../../../styles'
import {
  MenuWrapper,
  MenuButton,
  BecomeHost,
} from './styles'
import LoggedInModal from './LoggedInModal'

import { setShowBecomeHost, getIsValidHost } from '../../../../store/user'


export default function LoggedInMenu({ omitSearch }) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const isValidHost = useSelector(getIsValidHost)
  const isAdminPage = typeof window === 'object' && window.location.pathname.indexOf('/admin/') === 0

  return (
    <MenuWrapper>
      {!isValidHost && !isAdminPage && (
        <BecomeHost onClick={() => dispatch(setShowBecomeHost(true))}>
          <VenueIcon />
          <span>Become a Host</span>
        </BecomeHost>
      )}
      <MenuButton onClick={() => setIsOpen(true)}>
        <HamburgerIcon />
      </MenuButton>
      {isOpen && (
        <LoggedInModal
          setIsOpen={setIsOpen}
          omitSearch={omitSearch}
        />
      )}
    </MenuWrapper>
  )
}