import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { functions, db } from '../firebase'
import { getUser } from '../store/user'

const createStripeAccount = functions.httpsCallable('createStripeAccount')
const editStripeAccount = functions.httpsCallable('editStripeAccount')

// Set up account.
// When included on a page, will trigger a redirect.
export default function useHostPayout() {
  const user = useSelector(getUser)

  useEffect(() => {
    if (!user) return

    const { stripeAccountId } = user
    const isTest = window.location.hostname === 'localhost'
    const return_url = window.location.origin + '/'
    const refresh_url = window.location.origin + '/host/stripe/'
    const props = {
      isTest,
      refresh_url,
      return_url,
    }

    ;(
      stripeAccountId
      ? editStripeAccount({
        stripeAccountId,
        ...props,
      })
      : createStripeAccount(props)
    ).then(({ data: url }) => {
      if (url) {
        window.location.href = url
      }
    })
  }, [])
}