import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getShowDatePicker } from '../../../../../store/search'
import { colors, CloseIcon } from '../../../../styles'
import { countDays } from '../../../../helpers'

import Calendar from './Calendar'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Calendar} {
    align-self: center;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  margin-top: -5px;

  button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;

    svg {
      height: 14px;
      width: 14px;
    }
  }
`

const SubTitle = styled.div`
  color: ${colors.fadedText};
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 21px;
  border-bottom: 1px solid ${colors.mediumBorder};
  margin-bottom: 26px;
`

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  border-top: 1px solid ${colors.mediumBorder};
  padding-top: 28px;
`

const CancelButton = styled.button`
  font-weight: 500;
  color: ${colors.defaultText};
  background-color: transparent;
  font-size: 14px;
  padding: 0;
  padding-bottom: 2px;
  border: none;
  border-bottom: 1px solid ${colors.defaultText};
  line-height: 1;
`

const SubmitButton = styled.button`
  font-weight: 500;
  color: white;
  background-color: ${colors.primaryButton};
  font-size: 14px;
  border-radius: 4px;
  padding: 15px 69px;
  border: none;

  &:hover {
    opacity: 0.9;
  }
`

export default function SelectDatesModal({
  onDatePick,
  dateRange,
  onCancel,
  onSubmit,
}) {
  const showDatePicker = useSelector(getShowDatePicker)

  const startDate = moment(dateRange.startDate).format('MMM D')
  const endDate = moment(dateRange.endDate).format('MMM D, YYYY')

  const days = countDays(dateRange)

  // NOTE: submit on close, unlike booking modal where we want to cancel on close
  return (
    <Modal
      centered
      className="date-picker"
      show={showDatePicker}
      onHide={onSubmit}
    >
      <Modal.Body>
        <ContentWrapper>
          <Title>
            <div>{days} Day{days > 1 ? 's' : ''}</div>
            <button onClick={onSubmit}>
              <CloseIcon />
            </button>
          </Title>
          <SubTitle>
            {dateRange.startDate.valueOf() === dateRange.endDate.valueOf() ? endDate : (
              <>{startDate} &ndash; {endDate}</>
            )}
          </SubTitle>
          <Calendar
            dateRange={dateRange}
            onDatePick={onDatePick}
          />
          <Buttons>
            <CancelButton onClick={onCancel}>
              Cancel
            </CancelButton>
            <SubmitButton onClick={onSubmit}>
              Next
            </SubmitButton>
          </Buttons>
        </ContentWrapper>
      </Modal.Body>
    </Modal>
  )
}
