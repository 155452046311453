import React from 'react';
import PropTypes from 'prop-types';

import { cancellationPolicies } from '../../helpers'

function CancellationPolicies({ cancellationPolicy }) {
  const chosenPolicy = cancellationPolicies.find(({ name }) => name === cancellationPolicy)
  if (!chosenPolicy) {
    return null
  }
  return (
    <>
      Cancel before {chosenPolicy.timeline} of event for a full refund.
    </>
  );
}

CancellationPolicies.propTypes = {
  cancellationPolicy: PropTypes.string
};

export default CancellationPolicies;
