import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'

import {
  getShowSignIn,
  getShowSignUp,
  getShowForgot,
  getShowBecomeHost,
  close,
} from '../../../store/user'

import LoginModal from './LoginModal'
import ForgotModal from './ForgotModal'
import SignupModal from './SignupModal'
import BecomeHost from './BecomeHost'

import './authmodal-styles.css'

function AuthModal() {
  const dispatch = useDispatch()
  const showSignIn = useSelector(getShowSignIn)
  const showSignUp = useSelector(getShowSignUp)
  const showForgot = useSelector(getShowForgot)
  const showBecomeHost = useSelector(getShowBecomeHost)

  return (
    <>
      <Modal
        centered
        className="auth-modal"
        show={showSignUp}
        onHide={() => {
          dispatch(close())
        }}
      >
        <SignupModal />
      </Modal>
      <Modal
        centered
        className="auth-modal"
        show={showSignIn}
        onHide={() => {
          dispatch(close())
        }}
      >
        <LoginModal />
      </Modal>
      <Modal
        centered
        className="auth-modal"
        show={showForgot}
        onHide={() => {
          dispatch(close())
        }}
      >
        <ForgotModal />
      </Modal>
      <Modal
        centered
        className="auth-modal"
        show={showBecomeHost}
        onHide={() => {
          dispatch(close())
        }}
      >
        <BecomeHost />
      </Modal>
    </>
  );
}

export default AuthModal;
