import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import {
  CloseIcon,
  CheckmarkIcon,
} from '../../styles'
import {
  ContentWrapper,
  Media,
  Content,
  Header,
  Title,
  SubTitle,
  PrimaryLink,
  CheckList
} from './styles'

import { close, getUser } from '../../../store/user'


const BecomeHostModal = () => {
  const dispatch = useDispatch()
  const onCloseClick = () => dispatch(close())
  const history = useHistory()
  const { hasVenues, hasPaymentInfo } = useSelector(getUser)

  return (
    <ContentWrapper>
      <Media />
      <Content>
        <Header>
          <div>
            <Title>Become a Host</Title>
            <SubTitle>
              Please follow the steps below to get started.
            </SubTitle>

          </div>
          <button onClick={onCloseClick}>
            <CloseIcon />
          </button>
        </Header>
        <CheckList>
          <div className="completed">
            <CheckmarkIcon />
            <div>
              Create a free Yedding account.
            </div>
          </div>
          <div className={hasVenues ? 'completed' : ''}>
            <CheckmarkIcon />
            <div>
              Submit your first venue.
            </div>
          </div>
          <div className={hasPaymentInfo ? 'completed' : ''}>
            <CheckmarkIcon />
            <div>
              Add your payout information.
            </div>
          </div>
        </CheckList>
        <PrimaryLink
          onClick={() => {
            dispatch(close())
            history.push(hasVenues ? '/host/stripe/' : '/host/venues/')
          }}
        >
          Add {hasVenues ? 'Payout Settings' : 'Venue'}
        </PrimaryLink>
      </Content>
    </ContentWrapper>
  )

};

export default BecomeHostModal;
