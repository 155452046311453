import { auth, db, arrayRemove, arrayUnion } from '.';
import { startHasHostedVenues } from './venues'

function handleError(setError, defaultMessage) {
  return error => {
    if (error && error.message) {
      setError(error.message)
    } else if (typeof error === 'string') {
      setError(error)
    } else {
      setError(defaultMessage)
    }
  }
}

export const resetUserPassword = (email, setSuccess, setError) => {
  auth
    .sendPasswordResetEmail(email)
    .then(setSuccess)
    .catch(handleError(setError, 'Failed to send reset password email'));
}

export const signInUser = ({ email, password }, setSuccess, setError) => {
  auth
    .signInWithEmailAndPassword(email, password)
    .then(setSuccess)
    .catch(handleError(setError, 'Failed to sign in'))
}

export const signOutUser = (setSuccess, setError) => {
  auth
    .signOut()
    .then(setSuccess)
    .catch(handleError(setError, 'Failed to sign out'))
}

export const signUpUser = ({
  socialId,
  email,
  password,
  firstName,
  lastName,
}, setSuccess, setError) => {
  auth
    .createUserWithEmailAndPassword(email, password)
    .then(userCredentials => {
      const { user } = userCredentials;
      db
        .collection('users')
        .doc(user.uid)
        .set({
          firstName,
          lastName,
          email,
          socialId,
          registerTime: Date.now(),
          savedVenues: [],
          // legacy fields:
          reservedVenues: [],
          chats: [],
        })
        .then(setSuccess)
        .catch(() => {
          setError('Failed to create account')
        })
        auth.currentUser.sendEmailVerification().then(() => {
          // Email verification sent!
        })
    })
    .catch(handleError(setError, 'Failed to create account'))
}

export const getUserData = (setUserData, callback) => {
  const user = auth.currentUser;

  db.collection('users')
    .doc(user.uid)
    .get()
    .then((doc) => {
      const userObject = {
        id: user.uid,
        ...doc.data()
      };
      // calculate host attributes
      startHasHostedVenues({
        setHasVenues(hasVenues) {
          userObject.hasVenues = hasVenues
          setUserData(userObject);
          if (callback) callback(userObject);
        }
      })
    })
    .catch(() => {
      console.error('There was an error getting user data')
    });
}

export const updateUserEmail = (email, setSuccess, setError) => {
  auth
    .currentUser
    .updateEmail(email)
    .then(setSuccess)
    .catch(handleError(setError, 'Failed to update email address'))
}

export const updateUserPassword = ({ email, currentPassword, nextPassword }, setSuccess, setError) => {
  auth
    .signInWithEmailAndPassword(email, currentPassword)
    .then(() => {
      auth
        .currentUser
        .updatePassword(nextPassword)
        .then(setSuccess)
        .catch(() => {
          setError('Failed to update password')
        })
    })
    .catch(() => {
      setError('Current password is incorrect')
    })
}

export const updateUserProfile = ({
  email,
  phoneNumber,
  firstName,
  lastName,
}, setSuccess, setError) => {
  const currentID = auth.currentUser.uid;
  db.collection('users')
    .doc(currentID)
    .update({
      email,
      phoneNumber,
      firstName,
      lastName,
    })
    .then(setSuccess)
    .catch(handleError(setError, 'Failed to update profile'))
}

export const addSavedVenue = (venueId, setUserData) => {
  // write venueId into the users SavedVenue array
  const currentID = auth.currentUser.uid;

  db.collection('users')
    .doc(currentID)
    .update({
      savedVenues: arrayUnion(venueId)
    })
    .then(() => {
      db.collection('users')
        .doc(currentID)
        .get()
        .then((doc) => {
          const userObject = {
            id: currentID,
            ...doc.data()
          };
          setUserData(userObject)
        })
        .catch(() => {
          console.error('There was an error getting user data');
        })
    })
    .catch(() => {
      console.error('There was an error adding venue to favorites')
    })
};

export const removeSavedVenue = (venueId, setUserData) => {
  // remove venueId from users SavedVenue array
  const currentID = auth.currentUser.uid;

  db.collection('users')
    .doc(currentID)
    .update({
      savedVenues: arrayRemove(venueId)
    })
    .then(() => {
      db.collection('users')
        .doc(currentID)
        .get()
        .then((doc) => {
          const userObject = {
            id: currentID,
            ...doc.data()
          };
          setUserData(userObject);
        })
        .catch(() => {
          console.error('There was an error getting user data');
        })
    })
    .catch(() => {
      console.error('There was an error removing venue from favorites');
    })
};
