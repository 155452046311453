import moment from 'moment';

import { firebase, db, auth, storageRef } from '.';
import { isAnyLocation, isAvailable, getHostedBookingsByVenue } from '../components/helpers';

const PAGE_SIZE = 20;
const MAX_PAGES = 10;
const MAX_RESULTS = MAX_PAGES * PAGE_SIZE;

// During photo upload, photos are resized for the web. If we ever
// want to change the web size, we can adjust the Firebase extension
// and new resized images are generated. Consequently, we'd prefer
// to keep the venue's `photos` array as the original photo's filepath,
// and then dynamically adjust the photo's filepath to whatever path
// is the latest extension settings.
const RESIZE_SETTING = '_1000x1000'
const getPhotosForWeb = photos => {
  if (!Array.isArray(photos) || !photos.length) {
    return []
  }
  return photos.map(photo => {
    const url = new URL(photo.url)
    url.pathname += RESIZE_SETTING
    const thumbnail = url.toString()
    return {
      ...photo,
      thumbnail
    }
  })
}


export const geoCode = async location => {
  const locationEncoded = location.replace(' ', '+');
  const reqString = `https://maps.googleapis.com/maps/api/geocode/json?address=${locationEncoded}&key=${process.env.REACT_APP_GOOGLE_MAPS}`;
  const res = await fetch(reqString);
  const data = await res.json();
  if (data.status !== 'OK' || !data.results) {
    throw new Error(`${data.status}: Something went wrong with locating your Venue. Double check that there are no typos in your venue address`);
  }
  const { geometry, partial_match } = data.results[0] || {}
  const { lat, lng } = geometry.location;
  const exactMatch = partial_match !== true;
  return [ Number(lat), Number(lng), exactMatch ];
};

export const startSearchResults = async ({
  setFetching,
  setSearchResults,
  location,
  numGuests,
  startDate,
  endDate,
}) => {
  setFetching(true)
  let search =
    db.collection('venues')
      .where('verified', '==', true)

  const DISTANCE_DELTA = 0.25 // 35 mile circumference from search coordinates. ("1" is 138 mile circumference)
  const hasDefinedLocation = !isAnyLocation(location)
  let longitude, latitude;
  if (hasDefinedLocation) {
    try {
      const [ _latitude, _longitude ] = await geoCode(location);
      longitude = Number(_longitude)
      latitude = Number(_latitude)
      search = search.where('location.lat', '>=', latitude - DISTANCE_DELTA)
      search = search.where('location.lat', '<=', latitude + DISTANCE_DELTA)
    } catch (e) {
      console.error(e)
    }
  }

  numGuests = Number(numGuests)

  const hasDefinedDate = startDate !== 'none' && endDate !== 'none'

  search
    .limit(MAX_RESULTS)
    .get()
    .then(async (venuesSnapshot) => {
      let venues = venuesSnapshot?.docs?.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) || []

      venues = venues.filter(venue => (
        (
          // location match
          !hasDefinedLocation || (
            venue.location.lng >= longitude - DISTANCE_DELTA
            && venue.location.lng <= longitude + DISTANCE_DELTA
          )
        ) && (
          // guest match
          !numGuests || venue.maxGuests >= numGuests
        ) && (
          // date-availability match
          !hasDefinedDate
          || isAvailable({ startDate, endDate }, venue)
        )
      ))

      // Sort by distance from searched location
      venues.forEach(venue => {
        venue.distance = Math.abs(longitude - venue.location.lng)
        venue.distance += Math.abs(latitude - venue.location.lat)
        return venue
      })
      venues.sort((a, b) => {
        if (a.distance < b.distance) {
          return -1
        }
        if (a.distance > b.distance) {
          return 1
        }
        return 0
      })

      // Add thumbnails
      venues.forEach(venue => {
        venue.photos = getPhotosForWeb(venue.photos)
      })

      setSearchResults({
        venues,
        pageSize: PAGE_SIZE,
        totalPages: Math.ceil(venues.length / PAGE_SIZE),
        hasResults: venues.length > 0,
      })

      setTimeout(() => {
        setFetching(false)
      }, 10)
    })
};

export const startFavoritedResults = async ({
  setFetching,
  setSearchResults,
  user,
}) => {
  setFetching(true)
  const { savedVenues } = user

  if (!Array.isArray(savedVenues) || !savedVenues.length) {
    setSearchResults({
      venues: [],
      pageSize: PAGE_SIZE,
      totalPages: 0,
      hasResults: false,
    })
    setTimeout(() => {
      setFetching(false)
    }, 10)
    return
  }

  db.collection('venues')
    .where('verified', '==', true)
    .where(firebase.firestore.FieldPath.documentId(), 'in', savedVenues)
    .limit(MAX_RESULTS)
    .get()
    .then(async (venuesSnapshot) => {
      let venues = venuesSnapshot?.docs?.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) || []

      // Add thumbnails
      venues.forEach(venue => {
        venue.photos = getPhotosForWeb(venue.photos)
      })

      setSearchResults({
        venues,
        pageSize: PAGE_SIZE,
        totalPages: Math.ceil(venues.length / PAGE_SIZE),
        hasResults: venues.length > 0,
      })
      setTimeout(() => {
        setFetching(false)
      }, 10)
    })
};

export const startReservedResults = async ({
  setFetching,
  setSearchResults,
  user,
}) => {
  setFetching(true)

  const bookings = Object.values(user.bookings || {}).filter(booking => (
    booking.stripe_id && booking.venue_id && booking.amount && booking.start && booking.end
  ))

  // output a new collection, "reservations", from venue object + booking object; use stripe ID (since venues can be booked multiple times)
  if (!Array.isArray(bookings) || !bookings.length) {
    setSearchResults({
      venues: [],
      pageSize: PAGE_SIZE,
      totalPages: 0,
      hasResults: false,
    })
    setTimeout(() => {
      setFetching(false)
    }, 10)
    return
  }

  const venueIds = bookings.map(booking => booking.venue_id)
  db.collection('venues')
    .where(firebase.firestore.FieldPath.documentId(), 'in', venueIds)
    .limit(MAX_RESULTS)
    .get()
    .then(async (venuesSnapshot) => {
      const venues = venuesSnapshot?.docs?.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) || []

      const reservations = bookings.map(booking => {
        const venue = venues.find(venue => venue.id === booking.venue_id)
        if (!venue) {
          return null
        }
        const photos = getPhotosForWeb(venue.photos) // Add thumbnails
        return {
          ...venue,
          photos,
          booking,
          venue_id: venue.id,
          id: booking.stripe_id,
        }
      }).filter(value => !!value)

      reservations.sort((a, b) => {
        if (a.booking.start < b.booking.start) {
          return 1
        }
        if (a.booking.start > b.booking.start) {
          return -1
        }
        return 0
      })

      setSearchResults({
        venues: reservations,
        pageSize: PAGE_SIZE,
        totalPages: Math.ceil(reservations.length / PAGE_SIZE),
        hasResults: reservations.length > 0,
      })
      setTimeout(() => {
        setFetching(false)
      }, 10)
    })
};

export const startHostReservedResults = async ({
  setFetching,
  setSearchResults,
  user,
}) => {
  // if user has no bookings
  if (!user || !user.hostedBookings || !Object.keys(user.hostedBookings).length) {
    setSearchResults({
      venues: [],
      pageSize: PAGE_SIZE,
      totalPages: 0,
      hasResults: false,
    })
    return
  }

  const hostedBookingsByVenue = getHostedBookingsByVenue(user)

  setFetching(true)

  db.collection('venues')
    .where('userId', '==', auth.currentUser.uid)
    .get()
    .then(async (venuesSnapshot) => {
      const venues = (venuesSnapshot?.docs?.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) || []).filter(venue => (
        // filter out venues with no bookings.
        hostedBookingsByVenue[venue.id]
      ))

      const reservations = venues.reduce((reservations, venue) => ([
        ...reservations,
        ...hostedBookingsByVenue[venue.id].map(booking => {
          const photos = getPhotosForWeb(venue.photos) // Add thumbnails
          return {
            ...venue,
            photos,
            booking: {
              ...booking,
            },
            totalBookings: hostedBookingsByVenue[venue.id].length,
            venue_id: venue.id,
            id: booking.stripe_id
          }
        })
      ]), [])

      reservations.sort((a, b) => {
        if (a.booking.start < b.booking.start) {
          return 1
        }
        if (a.booking.start > b.booking.start) {
          return -1
        }
        return 0
      })

      setSearchResults({
        venues: reservations,
        pageSize: PAGE_SIZE,
        totalPages: Math.ceil(reservations.length / PAGE_SIZE),
        hasResults: reservations.length > 0,
      })
      setTimeout(() => {
        setFetching(false)
      }, 10)
    })
};

export const startHostedResults = ({
  setFetching,
  setSearchResults,
  user,
}) => {
  setFetching(true)

  const hostedBookingsByVenue = getHostedBookingsByVenue(user)

  db.collection('venues')
    .where('userId', '==', user.id)
    .get()
    .then(async (venuesSnapshot) => {
      const venues = venuesSnapshot?.docs?.map(doc => ({
        id: doc.id,
        ...doc.data(),
        totalBookings: (hostedBookingsByVenue[doc.id] || []).length
      })) || []

      // Add thumbnails
      venues.forEach(venue => {
        venue.photos = getPhotosForWeb(venue.photos)
      })

      setSearchResults({
        venues,
        pageSize: PAGE_SIZE,
        totalPages: Math.ceil(venues.length / PAGE_SIZE),
        hasResults: venues.length > 0,
      })
      setTimeout(() => {
        setFetching(false)
      }, 10)
    })
}



export const startHasHostedVenues = ({
  setHasVenues,
}) => {
  const user = auth.currentUser
  db.collection('venues')
    .where('userId', '==', user.uid)
    .get()
    .then(async (venuesSnapshot) => {
      const venuesCount = venuesSnapshot?.docs?.length || 0
      setHasVenues(venuesCount > 0)
    })
}

export const addHostedVenue = ({ props }, onSuccess, onError) => {
    db.collection('venues')
      .add(props)
      .then(onSuccess)
      .catch(onError)
}

export const editHostedVenue = ({ id, props }, onSuccess, onError) => {
  if (!id) return onError()
  db.collection('venues')
    .doc(id)
    .update(props)
    .then(onSuccess)
    .catch(onError)
}

export const addVenuePhoto = async ({ photo }, onSuccess, onError) => {
  if (!photo.name) {
    onError('Photo is missing a filename')
    return
  }
  // Adding timestamp in case a photo is uploaded again, and then user cancels the edit, we don't want to change the original image.
  // And by nesting in the user's ID instead of venue ID, we can more easily clean up when users abuse file upload.
  const ref = `photos/${auth.currentUser.uid}/${photo.name.replace(/\W+/g, '-')}_${Date.now()}`
  const task = storageRef.child(ref).put(photo)
  task.on(
    'state_changed',
    () => {},
    () => onError(),
    () => {
      task.snapshot.ref
        .getDownloadURL()
        .then(async rawUrl => {
          // clean up URL string
          const urlParsed = new URL(rawUrl)
          urlParsed.searchParams.delete('token') // not needed, we allow reads on photos
          const url = urlParsed.toString()
          onSuccess({ ref, url })
        })
        .catch(e => {
          console.error(e)
          onError()
        })
    }
  );
}

export const getVenue = ({
  id,
  setVenue,
  setFetching
}) => {
  if (!id) {
    setVenue(null)
    return
  }
  db.collection('venues')
    .doc(id)
    .get()
    .then((doc) => {
      const venueObject = {
        id: doc.id,
        ...doc.data()
      }
      venueObject.photos = getPhotosForWeb(venueObject.photos)  // Add thumbnails
      setVenue(venueObject)
      setTimeout(() => {
        setFetching(false)
      }, 10);
    })
    .catch(() => {
      alert('There was an error getting venue data')
    })
};


//////////////////////////////
//                          //
//     Legacy Endpoints     //
//                          //
//////////////////////////////

export const startSetHostedVenues = (setHostedVenues, setFetching) => {
  const user = auth.currentUser;
  setHostedVenues([]);
  db.collection('venues')
    .where('userId', '==', user.uid)
    .get()
    .then(async (venueSnapshot) => {
      await venueSnapshot.forEach((venueDoc) => {
        const venueData = {
          id: venueDoc.id,
          ...venueDoc.data()
        };
        setHostedVenues((prevVenues) => [...prevVenues, venueData]);
      });

      setTimeout(() => {
        setFetching(false);
      }, 100);
    });
};

export const deleteVenue = (venueId, setHostedVenues, hostedVenues) => {
  db.collection('venues')
    .doc(venueId)
    .delete()
    .then(setHostedVenues(...hostedVenues.filter((venue) => venue.id !== venueId)));
};

export const getListVenues = (venueIds, setVenues, setFetching) => {
  const venues = [];
  venueIds.forEach((venueId) => {
    db.collection('venues')
      .doc(venueId)
      .get()
      .then((venuesSnapshot) => {
        const venueData = {
          id: venuesSnapshot.id,
          ...venuesSnapshot.data()
        };
        console.log(venueData);
        venues.push(venueData);
      });
  });

  setVenues(venues);
  setTimeout(() => {
    setFetching(false);
  }, 300);
};

export const getUserRSVenues = (setSavedVenues, setReservedVenues, setFetching) => {
  const user = auth.currentUser;
  db.collection('users')
    .doc(user.uid)
    .get()
    .then((doc) => {
      const userObject = {
        id: user.uid,
        ...doc.data()
      };

      getListVenues(userObject.savedVenues, setSavedVenues, () => {});
      // console.log('Getting events....');
      db.collection('events')
        .where('guestId', '==', user.uid)
        .get()
        .then((snapshot) => {
          const ids = snapshot.docs.map((eventDoc) => eventDoc.data().venueId);
          getListVenues(ids, setReservedVenues, setFetching);
        });
    })
    .catch((error) => {
      console.log(error);
      alert('There was an error getting user data');
    });
};
